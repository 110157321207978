import React from 'react';
import styled from '@emotion/styled';
import SearchForm from './SearchForm';

const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.softLightGray};
`;

const Search = () => (
  <SearchContainer>
    <SearchForm />
  </SearchContainer>
);

export default Search;
