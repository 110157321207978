import { decorate, computed, observable, action } from 'mobx';
import api from '../api';
import { DEV, PARTNER_ID } from '../constants';
import filterStore from './filterStore';
import commonStore from './commonStore';
import userStore from './userStore';

const tmpProcessFlights = (flights) => {
  const numberedMapToArray = (map) => Object.entries(map).map(([, val]) => val);
  return flights.map((flight) => {
    const newFlight = { ...flight };
    if (flight.connectedSegmentsCount !== null) {
      newFlight.connectedSegmentsCount = numberedMapToArray(
        flight.connectedSegmentsCount,
      );
    }
    if (flight.connectedSegmentsDuration !== null) {
      newFlight.connectedSegmentsDuration = numberedMapToArray(
        flight.connectedSegmentsDuration,
      );
    }
    if (flight.segmentsAirport !== null) {
      newFlight.segmentsAirport = numberedMapToArray(flight.segmentsAirport);
    }
    if (flight.segmentsDuration !== null) {
      newFlight.segmentsDuration = numberedMapToArray(flight.segmentsDuration);
    }
    if (flight.segmentsTime !== null) {
      newFlight.segmentsTime = numberedMapToArray(flight.segmentsTime);
    }
    return newFlight;
  });
};

const PER_PAGE = 10;

class FlightSearchStore {
  flights;

  flightsDetailed;

  lastSearchId;

  lastSearchFlightsCount;

  lastSearchResultId;

  lastPage;

  resultsFiltered = false;

  isLoading = false;

  error = false;

  all = [];

  constructor() {
    if (DEV.STORE_FLIGHTS_IN_LOCAL_STORAGE) {
      const storedFlights = localStorage.getItem('DEV_FLIGHTS');
      if (storedFlights !== null) {
        this.flights = JSON.parse(storedFlights);
      }
    }
  }

  get lastSearchHasMoreFlights() {
    return this.lastPage * PER_PAGE < this.lastSearchFlightsCount;
  }

  applyFilter(filterParams) {
    this.isLoading = true;
    this.error = false;
    const requestData = {
      searchResultId: this.lastSearchResultId,
      filters: filterParams,
    };
    api.Avia.filterFlights(requestData).then(
      action((response) => {
        this.flights = tmpProcessFlights(response);
        if (DEV.STORE_FLIGHTS_IN_LOCAL_STORAGE) {
          localStorage.setItem('DEV_FLIGHTS', JSON.stringify(this.flights));
        }
        this.isLoading = false;
      }),
      action(() => {
        this.isLoading = false;
        this.error = true;
      }),
    );
  }

  getFlightDetailedById(id) {
    if (this.flightsDetailed !== undefined) {
      return this.flightsDetailed.find((flight) => flight.flightId === id);
    }
    return undefined;
  }

  loadFlightById(id) {
    return new Promise((resolve, reject) => {
      api.Avia.fetchFlight(id).then(
        (response) => {
          if (this.flightsDetailed === undefined) {
            this.flightsDetailed = response.results;
          } else if (
            !this.flightsDetailed.some((flight) => flight.flightId === id)
          ) {
            this.flightsDetailed.push(response.results[0]);
          }
          resolve(response);
        },
        () => reject(),
      );
    });
  }

  loadFlights(searchData, filterData) {
    const SYSTEM_ID = 'prime.avia.mobile';
    const formatDataFromQueryVars = (queryVars) => {
      const result = {};
      if (queryVars.taskId) {
        result.taskId = queryVars.taskId;
      }
      if (queryVars.customerId && queryVars.customerType) {
        result.customer = {
          id: queryVars.customerId,
          customerType: queryVars.customerType,
          profileType: 'CUSTOMER',
        };
      }
      return result;
    };
    let requestData;
    if (userStore.isAuthenticated) {
      const { currentUser, token } = userStore;
      requestData = {
        systemId: SYSTEM_ID,
        partnerId: PARTNER_ID,
        user: {
          id: currentUser.username,
          profileType: currentUser.profileType,
        },
        customer: {
          id: currentUser.username,
          customerType: currentUser.customerTypeId,
          profileType: currentUser.profileType,
        },
        ...formatDataFromQueryVars(commonStore.initialQueryVars),
        userToken: token,
        obj: searchData,
      };
    } else {
      requestData = {
        systemId: SYSTEM_ID,
        partnerId: PARTNER_ID,
        ...formatDataFromQueryVars(commonStore.initialQueryVars),
        obj: searchData,
      };
    }
    if (filterData && Object.entries(filterData).length !== 0) {
      requestData.obj.filters = filterData;
    }

    this.isLoading = true;
    this.error = false;

    api.Avia.startSearch(requestData).then(({ uid }) => {
      this.searchFlights(uid);
    });
  }

  searchFlights(uid, iteration = 0) {
    api.Avia.searchFlights(uid).then(
      action((response) => {
        if (response.statusCode === 400) {
          this.isLoading = false;
          this.error = true;
        }

        if (response.content.searchingIsDone) {
          this.lastPage = 1;
          this.lastSearchId = response.content.searchId;
          this.lastSearchFlightsCount = response.content.flightsCount;
          this.lastSearchResultId = response.content.searchResultId;
          filterStore.setInitialConstraintsAndValues(
            response.content.possibleFilters,
            response.content.possibleFilters,
          );
          this.all = response.content.results;
          this.flights = tmpProcessFlights(this.all.slice(0, PER_PAGE));

          this.isLoading = false;
        } else if (iteration < 999) {
          setTimeout(() => {
            this.searchFlights(uid, iteration + 1);
          }, 1000);
        }
      }),
    );
  }

  loadMoreFlights() {
    const currentIndex = this.lastPage * PER_PAGE;
    const items = this.all.slice(currentIndex, currentIndex + PER_PAGE);
    if (items.length) {
      this.flights = [...this.flights, ...tmpProcessFlights(items)];
      this.lastPage += 1;
    }
  }

  loadFlightsBySearchId(id) {
    this.isLoading = true;
    this.error = false;
    return new Promise((resolve, reject) => {
      api.Avia.fetchSearchById(id).then(
        action((response) => {
          this.lastSearchId = response.searchId;
          this.lastPage = 1;
          this.flights = tmpProcessFlights(response.results);
          if (DEV.STORE_FLIGHTS_IN_LOCAL_STORAGE) {
            localStorage.setItem('DEV_FLIGHTS', JSON.stringify(this.flights));
          }
          this.isLoading = false;
          resolve(response);
        }),
        action(() => {
          this.isLoading = false;
          this.error = true;
          reject();
        }),
      );
    });
  }

  clearFlights() {
    this.flights = undefined;
  }
}

decorate(FlightSearchStore, {
  lastPage: observable,
  lastSearchId: observable,
  flights: observable,
  flightsDetailed: observable,
  isLoading: observable,
  error: observable,
  lastSearchHasMoreFlights: computed,
  loadFlights: action,
  loadMoreFlights: action,
  loadFlightsBySearchId: action,
  clearFlights: action,
});

export default new FlightSearchStore();
