import moment from 'moment';

/* eslint-disable no-use-before-define */
function getTargetEnv() {
  const envVarTargetEnv = process.env.REACT_APP_TARGET_ENV;
  let tEnv = TARGET_ENVS.DEV;
  if (TARGET_ENVS[envVarTargetEnv] !== undefined) {
    tEnv = TARGET_ENVS[envVarTargetEnv];
  }
  return tEnv;
}

const TARGET_ENVS = {
  DEV: 0,
  DEMO: 1,
  PRODUCTION: 2,
};

const DEPOSIT_OFF = true;

const TARGET_ENV = getTargetEnv();

/* eslint-disable import/no-mutable-exports */
let PRIME_API_ROOT;
let AVIA_API_ROOT;
let AUTH_URL;
let PAYMENT_GATEWAY_URL;
if (TARGET_ENV === TARGET_ENVS.DEV || TARGET_ENV === TARGET_ENVS.DEMO) {
  PRIME_API_ROOT = 'https://demo.primeconcept.co.uk/v3/';
  AVIA_API_ROOT = 'https://avia-dev.primeconcept.co.uk/api/';
  AUTH_URL = 'https://expert-dev.primeconcept.co.uk/api/authorization/token';
  PAYMENT_GATEWAY_URL = 'https://demo.primeconcept.co.uk/payments/order';
} else if (TARGET_ENV === TARGET_ENVS.PRODUCTION) {
  PRIME_API_ROOT = 'https://api.primeconcept.co.uk/v3/';
  AVIA_API_ROOT = 'https://avia.prime.travel/api/';
  AUTH_URL = 'https://expert.prime.travel/api/authorization/token';
  PAYMENT_GATEWAY_URL = 'https://pay.primeconcept.co.uk/payments/order';
}

const DEFAULT_ROUTE = '/search';
const DEFAULT_AUTH_RETURN_ROUTE = '/search';
const DEFAULT_CUSTOMER_TYPE = 268435488;

const DEV_ACCESS_TOKEN = '566df667-3e5d-4a84-842d-d51e9204b9ca';
const SEARCH_FORM_MODES = {
  SIMPLE_ROUTE: 0,
  COMPLEX_ROUTE: 1,
};
const FLIGHT_CLASSES = {
  ECONOMY: 'ECONOM',
  BUSINESS: 'BUSINESS',
  FIRST: 'FIRST',
};
const USER_TYPES = {
  CUSTOMER: 0,
  ASSISTANT: 1,
};
const POPUP_TYPES = {
  PASSENGER_INCOMPLETE: 0,
  GENERIC_ERROR: 1,
  NO_SEATS: 2,
  PRICE_CHANGED: 3,
  DATES_ERROR: 4,
  RETURN_TO_PAYMENT: 5,
  STALE_DATA_ERROR: 6,
  BOOKING_CANCELLED: 7,
};

let PAYMENT_METHODS;
if (TARGET_ENV === TARGET_ENVS.PRODUCTION) {
  PAYMENT_METHODS = {
    CARD: 'CARD',
  };
} else {
  PAYMENT_METHODS = {
    CARD: 'CARD',
    DEPOSIT: 'DEPOSIT',
  };
}

const ID_CARD_TYPES = {
  // Паспорт РФ
  PASSPORT: 'PASSPORT',
  // Свидетельство о рождении РФ
  BIRTH_NOTIFICATION: 'BIRTH_NOTIFICATION',
  // Загранпаспорт РФ
  FOREIGN_PASSPORT: 'FOREIGN_PASSPORT',
  // Паспорт иного государства
  CIVIL_PASSPORT: 'CIVIL_PASSPORT',
};

const PARTNER_ID = 1;

const DEV = {
  USE_TOKEN_FROM_CONSTANTS: false,
  STORE_FLIGHTS_IN_LOCAL_STORAGE: false,
  STORE_PASSENGERS_IN_LOCAL_STORAGE: false,
  PREFILL_PASSENGERS_COUNT: false,
  PREFILL_PASSENGERS_CATEGORIES: {
    adult: 1,
    child: 1,
  },
  PREFILL_SEARCH: false,
  PREFILL_SEARCH_ROUTES: [
    {
      from: {
        airportName: 'Шереметьево',
        airportCode: 'SVO',
        cityName: 'Москва',
      },
      to: {
        airportName: 'Пулково',
        airportCode: 'LED',
        cityName: 'Санкт-Петербург',
      },
      dates: {
        startDate: moment('2019-11-10'),
        endDate: moment('2019-11-19'),
      },
    },
  ],
};

const I18N_DEBUG = TARGET_ENV === TARGET_ENVS.DEV;
const I18N_DEFAULT_LNG = 'ru';
const I18N_QUERY_PARAM_NAME = 'lang';

export {
  TARGET_ENV,
  TARGET_ENVS,
  I18N_DEBUG,
  I18N_DEFAULT_LNG,
  I18N_QUERY_PARAM_NAME,
  USER_TYPES,
  POPUP_TYPES,
  ID_CARD_TYPES,
  DEFAULT_ROUTE,
  DEFAULT_AUTH_RETURN_ROUTE,
  DEFAULT_CUSTOMER_TYPE,
  PRIME_API_ROOT,
  AVIA_API_ROOT,
  AUTH_URL,
  PAYMENT_GATEWAY_URL,
  DEV_ACCESS_TOKEN,
  SEARCH_FORM_MODES,
  FLIGHT_CLASSES,
  PAYMENT_METHODS,
  PARTNER_ID,
  DEV,
  DEPOSIT_OFF,
};
