import { decorate, observable, action } from 'mobx';

class PopupsStore {
  visible;

  type;

  props;

  promise;

  resolve;

  openPopup(popupType, props) {
    this.type = popupType;
    this.visible = true;
    this.props = props;
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });
    return this.promise;
  }

  closePopup() {
    this.visible = false;
  }
}

decorate(PopupsStore, {
  visible: observable,
  type: observable,
  props: observable,
  openPopup: action,
  closePopup: action,
});

export default new PopupsStore();
