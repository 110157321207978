import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import {
  AVIA_API_ROOT,
  PRIME_API_ROOT,
  AUTH_URL,
  PAYMENT_GATEWAY_URL,
  PARTNER_ID,
} from './constants';
import { addQueryParamsToUrl } from './util';

let TRANSACTION_ID;

const superagent = superagentPromise(_superagent, global.Promise);

const cacheRequestFn = (fn) => {
  const cache = {};
  return (...args) => {
    const paramsJson = JSON.stringify(args);
    if (cache[paramsJson]) {
      if (cache[paramsJson] instanceof Promise) {
        return cache[paramsJson];
      }
      return new Promise((resolve) => {
        resolve(cache[paramsJson]);
      });
    }
    cache[paramsJson] = new Promise((resolve, reject) => {
      fn(...args).then((responseBody) => {
        cache[paramsJson] = responseBody;
        resolve(responseBody);
      }, reject);
    });
    return cache[paramsJson];
  };
};

const handleError = (err) => {
  if (err !== null) {
    // eslint-disable-next-line
    console.error(err);
  }
};

const extractBody = (response) => {
  if (!TRANSACTION_ID) {
    TRANSACTION_ID = response.headers['x-transaction-id'];
  }
  return response.body !== null ? response.body : response.text;
};

class SuperagentWrapper {
  constructor(apiRoot) {
    this.apiRoot = apiRoot;
  }

  get(url, headers = {}) {
    const newHeaders = TRANSACTION_ID
      ? { ...headers, 'x-transaction-id': TRANSACTION_ID }
      : headers;
    return superagent
      .get(`${this.apiRoot}${url}`)
      .set(newHeaders)
      .end(handleError)
      .then(extractBody);
  }

  post(url, body, headers = {}) {
    const relativeUrl = url.indexOf('://') === -1;
    const finalUrl = relativeUrl ? `${this.apiRoot}${url}` : url;
    const newHeaders = TRANSACTION_ID
      ? { ...headers, 'x-transaction-id': TRANSACTION_ID }
      : headers;
    return superagent
      .post(finalUrl, body)
      .set(newHeaders)
      .end(handleError)
      .then(extractBody);
  }

  delete(url, headers = {}) {
    const newHeaders = TRANSACTION_ID
      ? { ...headers, 'x-transaction-id': TRANSACTION_ID }
      : headers;
    return superagent
      .del(`${this.apiRoot}${url}`)
      .set(newHeaders)
      .end(handleError)
      .then(extractBody);
  }
}

const agentAvia = new SuperagentWrapper(AVIA_API_ROOT);
const agentPrime = new SuperagentWrapper(PRIME_API_ROOT);

const Prime = {
  me: (token) => agentPrime.get(`me?access_token=${token}`),
  auth: (login, password, customerType) =>
    agentPrime.post(AUTH_URL, {
      username: login,
      password,
      customerType,
    }),
  getCustomerData: (customerId, token) =>
    agentPrime.get(`assistant/customers/${customerId}`, {
      Authorization: `Bearer ${token}`,
    }),
  generatePaymentUrl: (orderId, bookingId, customerType) => {
    let successUrl = `${window.location.protocol}//${window.location.host}/?payment_redirect=true&booking_id=${bookingId}`;
    if (customerType !== undefined) {
      successUrl = addQueryParamsToUrl(successUrl, { customerType });
    }
    const successUrlEncoded = window.encodeURIComponent(successUrl);
    return `${PAYMENT_GATEWAY_URL}/${orderId}?success_url=${successUrlEncoded}`;
  },
  redirectToPayment: (orderId, bookingId) => {
    const successUrl = `${window.location.protocol}//${window.location.host}/?payment_redirect=true&booking_id=${bookingId}`;
    const successUrlEncoded = window.encodeURIComponent(successUrl);
    window.location.href = `${PAYMENT_GATEWAY_URL}/${orderId}?success_url=${successUrlEncoded}`;
  },
};

const Avia = {
  getPassengersFromCRM: (token) =>
    agentAvia.get(`prime-avia-integration/avia/catalog/passengers/v2`, {
      Authorization: `Bearer ${token}`,
    }),
  getLocationByCode: cacheRequestFn((code) =>
    agentAvia.get(`soap-client/avia/catalog/location/code?query=${code}`),
  ),
  findLocation: (query) =>
    agentAvia.get(`soap-client/avia/catalog/location/find?query=${query}`),
  getPopularLocations: cacheRequestFn(() =>
    agentAvia.get('soap-client/avia/catalog/location/popular'),
  ),
  getAllCountries: cacheRequestFn(() =>
    agentAvia.get('soap-client/catalog/countries/all'),
  ),
  getIdDocuments: cacheRequestFn(() =>
    agentAvia.get(`soap-client/avia/catalog/documents/type`),
  ),
  getContactTypes: (token) =>
    agentAvia.get(`prime-avia-integration/customer/contacts/types`, {
      Authorization: `Bearer ${token}`,
    }),
  getSegmentRemarks: cacheRequestFn((segmentId) =>
    agentAvia.get(
      `soap-client/avia/booking/flight/remarks/${segmentId}?partnerId=${PARTNER_ID}`,
    ),
  ),
  startSearch: (data) =>
    agentAvia.post('soap-client/avia/search/async/search', data),
  searchFlights: (uid) =>
    agentAvia.get(`soap-client/avia/search/result/${uid}?time=0`),
  fetchMoreFlights: (searchId, page, perPage) =>
    agentAvia.get(
      `soap-client/avia/search/result?searchId=${searchId}&page=${page}&size=${perPage}`,
    ),
  filterFlights: (data) =>
    agentAvia.post('soap-client/avia/search/v2/filter', data),
  fetchSearchById: (id) =>
    agentAvia.get(`soap-client/avia/search/flights?id=${id}`),
  fetchFlight: (id) => agentAvia.get(`soap-client/avia/search/V2/flight/${id}`),
  checkPricing: (data) =>
    agentAvia.post('soap-client/avia/search/flight/price/check', data),
  createReservation: (data) =>
    agentAvia.post('soap-client/avia/booking/reservation/create', data),
  preparePayment: (data) =>
    agentAvia.post('prime-avia-integration/avia/booking/payment/prepare', data),
  getBookingInfo: (data) =>
    agentAvia.post('soap-client/avia/booking/info/V2', data),
  createBookingRequest: (data) =>
    agentAvia.post('soap-client/avia/booking/ticketing/request', data),
  savePassenger: (data, token) =>
    agentAvia.post(
      'prime-avia-integration/avia/catalog/passengers/save',
      data,
      {
        Authorization: `Bearer ${token}`,
      },
    ),
  getCustomerLoyaltyCards: (token) =>
    agentAvia.get(`prime-avia-integration/avia/catalog/discounts`, {
      Authorization: `Bearer ${token}`,
    }),
  getLoyaltyCardTypes: (token) =>
    agentAvia.get(`prime-avia-integration/avia/catalog/discounts/types`, {
      Authorization: `Bearer ${token}`,
    }),
  saveLoyaltyCard: (data, token) =>
    agentAvia.post('prime-avia-integration/avia/catalog/discounts', data, {
      Authorization: `Bearer ${token}`,
    }),
  getLoyaltyCardById: (id, token) =>
    agentAvia.get(`prime-avia-integration/avia/catalog/discounts/${id}`, {
      Authorization: `Bearer ${token}`,
    }),
  deleteLoyaltyCard: (id, token) =>
    agentAvia.delete(`prime-avia-integration/avia/catalog/discounts/${id}`, {
      Authorization: `Bearer ${token}`,
    }),
  downloadReceipt: (receiptId) => {
    window.open(
      `${AVIA_API_ROOT}soap-client/avia/booking/receipt/get/${receiptId}`,
      '_blank',
    );
  },
};

const exports = {
  Prime,
  Avia,
};

export default exports;
