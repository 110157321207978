import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';

const Container = styled.div`
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 15px 15px 15px 41px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto;
  width: 16px;
  height: 16px;
`;

const Text = styled.div`
  display: inline-block;
  font-size: 16px;
  width: 112px;
`;
const DateText = styled(Text)`
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColor};
`;
const PlaceholderText = styled(Text)`
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColorLight};
`;

const DatePicker = (props) => {
  const theme = useTheme();
  const { value, error, placeholder } = props;
  const dateSelected = value !== undefined;
  let dateFormatted = dateSelected ? moment(value).format('D MMM') : undefined;
  if (
    dateFormatted !== undefined &&
    dateFormatted[dateFormatted.length - 1] === '.'
  ) {
    dateFormatted = dateFormatted.slice(0, -1);
  }
  return (
    <Container>
      {dateSelected && (
        <div>
          <IconContainer>
            <CalendarIcon fill={theme.colors.textColorLight} />
          </IconContainer>
          <DateText error={error}>{dateFormatted}</DateText>
        </div>
      )}
      {!dateSelected && (
        <div>
          <IconContainer>
            <PlusIcon fill={theme.colors.textColorLight} />
          </IconContainer>
          <PlaceholderText error={error}>{placeholder}</PlaceholderText>
        </div>
      )}
    </Container>
  );
};

export default DatePicker;
