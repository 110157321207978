import { decorate, observable, action } from 'mobx';
import api from '../api';

/* eslint-disable class-methods-use-this */

class RemarksStore {
  loadSegmentRemarks(segmentId) {
    return new Promise((resolve, reject) => {
      api.Avia.getSegmentRemarks(segmentId).then((result) => {
        if (result.statusCode !== 400) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }
}

decorate(RemarksStore, {
  remarks: observable,
  isLoading: observable,
  error: observable,
  authenticateWithToken: action,
});

export default new RemarksStore();
