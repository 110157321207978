import { decorate, observable, computed, action } from 'mobx';
import moment from 'moment';
import { SEARCH_FORM_MODES, FLIGHT_CLASSES, DEV } from '../constants';
import i18n from '../i18n';
import { parseFlightInfoString } from '../util';
import api from '../api';
import userStore from './userStore';
import { processLocations } from './locationSearchStore';

const LOCAL_STORAGE_KEY = 'ParamsAvia-98ec164f';
const CACHE_MAX_AGE_HOURS = 48;

/* eslint-disable class-methods-use-this */
class SearchStore {
  routes = [{}];

  editedRoute;

  editedLocationField;

  searchMode = SEARCH_FORM_MODES.SIMPLE_ROUTE;

  passengersCount = {
    adults: 1,
    children: 0,
    infants: 0,
  };

  flightClass = FLIGHT_CLASSES.ECONOMY;

  infoResolveInProgress = false;

  datesConstrains = { minDate: moment(), maxDate: moment().add(12, 'months') };

  // Переменная отвечает за то, стоит ли открывать
  // календарь автоматически. Сразу после автоматического открытия календаря
  // значение становится false, чтобы пользователь мог закрыть его, не выбрав даты.
  // После закрытия календаря значение снова становится true
  shouldAutoOpenCalendar = true;

  constructor() {
    if (DEV.PREFILL_SEARCH) {
      this.routes = DEV.PREFILL_SEARCH_ROUTES;
    }
    this._tryLoadCachedParams();
  }

  _tryLoadCachedParams() {
    const lsParamsStr = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lsParamsStr !== null) {
      const cachedParams = JSON.parse(lsParamsStr);
      const now = moment();
      const cacheTimestamp = moment(cachedParams.time);
      const cacheAge = now.diff(cacheTimestamp, 'hours');
      if (cacheAge > CACHE_MAX_AGE_HOURS) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      } else {
        const {
          routes,
          flightClass,
          passengersCount,
        } = this._deserializeParams(cachedParams);
        this.routes = routes;
        this.flightClass = flightClass;
        this.passengersCount = passengersCount;
        if (this.routes.length > 1) {
          this.searchMode = SEARCH_FORM_MODES.COMPLEX_ROUTE;
        }
      }
    }
  }

  _persistParams() {
    localStorage.setItem(LOCAL_STORAGE_KEY, this._serializeParams());
  }

  _deserializeParams(value) {
    const { routes, flightClass, passengersCount } = value;
    return {
      flightClass,
      passengersCount,
      routes: routes.map((route) => {
        const routeCopy = {
          ...route,
        };
        if (routeCopy.dates !== undefined) {
          if (routeCopy.dates.startDate !== undefined) {
            routeCopy.dates.startDate = moment(routeCopy.dates.startDate);
          }
          if (routeCopy.dates.endDate !== undefined) {
            routeCopy.dates.endDate = moment(routeCopy.dates.endDate);
          }
        }
        return routeCopy;
      }),
    };
  }

  _serializeParams() {
    return JSON.stringify({
      time: moment().toISOString(),
      routes: this.routes.map((route) => {
        const routeCopy = {};
        if (route.from !== undefined) {
          routeCopy.from = { ...route.from };
        }
        if (route.to !== undefined) {
          routeCopy.to = { ...route.to };
        }
        if (route.dates !== undefined) {
          routeCopy.dates = {};
          if (route.dates.startDate !== undefined) {
            routeCopy.dates.startDate = route.dates.startDate.toISOString();
          }
          if (route.dates.endDate !== undefined) {
            routeCopy.dates.endDate = route.dates.endDate.toISOString();
          }
        }
        return routeCopy;
      }),
      flightClass: this.flightClass,
      passengersCount: { ...this.passengersCount },
    });
  }

  _isRouteValid(route) {
    return (
      route.from !== undefined &&
      route.to !== undefined &&
      route.dates !== undefined &&
      route.dates.startDate !== undefined
    );
  }

  get totalPassengersCount() {
    return Object.entries(this.passengersCount).reduce(
      (total, [, count]) => total + count,
      0,
    );
  }

  get inputErrors() {
    const now = moment();
    const errors = {};
    const routeErrors = [];
    let routeError;
    this.routes.forEach((route) => {
      routeError = {};
      if (route.from === undefined) {
        routeError.from = 'not selected';
      }
      if (route.to === undefined) {
        routeError.to = 'not selected';
      }
      if (route.dates !== undefined) {
        if (route.dates.startDate !== undefined) {
          if (moment(route.dates.startDate).isBefore(now, 'day')) {
            routeError.startDate = 'before now';
          }
        } else {
          routeError.startDate = 'not selected';
        }
        if (route.dates.endDate !== undefined) {
          if (moment(route.dates.endDate).isBefore(now, 'day')) {
            routeError.endDate = 'before now';
          }
        }
      } else {
        routeError.startDate = 'not selected';
      }
      routeErrors.push(routeError);
    });
    if (
      routeErrors.length > 1 &&
      this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE
    ) {
      routeErrors.splice(1, routeErrors.length - 1);
    }
    if (routeErrors.some((error) => Object.entries(error).length > 0)) {
      errors.routes = routeErrors;
    }
    if (this.totalPassengersCount > 9 || this.totalPassengersCount < 1) {
      errors.passengersCount = 'too many or too little';
    }
    if (this.passengersCount.adults < this.passengersCount.infants) {
      errors.passengersCount = 'too many infants';
    }
    return errors;
  }

  get isInputValid() {
    // Нужно проводить только валидацию выбранных перелётов
    // т.к. класс перелёта и количество пассажиров изначально
    // установлены в валидные значения и UI не позволяет
    // пользователю выбрать невалидные
    return this.routes
      .map(this._isRouteValid)
      .reduce((validity, allValid) => validity && allValid, true);
  }

  get infoString() {
    return [
      ...this.routes.map((route) => {
        if (route.dates.endDate !== undefined) {
          return (
            route.from.airportCode +
            route.dates.startDate.format('DDMMYYYY') +
            route.to.airportCode +
            route.to.airportCode +
            route.dates.endDate.format('DDMMYYYY') +
            route.from.airportCode
          );
        }
        return (
          route.from.airportCode +
          route.dates.startDate.format('DDMMYYYY') +
          route.to.airportCode
        );
      }),
      this.flightClass.toLowerCase()[0],
      ...Object.entries(this.passengersCount).map(([, pc]) => pc.toString()),
    ].join('');
  }

  get airportSummaryString() {
    const firstRoute = this.routes[0];
    const lastRoute = this.routes[this.routes.length - 1];
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      return `${firstRoute.from.airportCode} - ${firstRoute.to.airportCode}`;
    }
    if (this.searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
      return `${firstRoute.from.airportCode} ... ${lastRoute.to.airportCode}`;
    }
    return '';
  }

  get destinationsList() {
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      const firstRoute = this.routes[0];
      if (Object.keys(firstRoute).length > 0) {
        if (firstRoute.dates.endDate) {
          return [
            { from: firstRoute.from, to: firstRoute.to },
            { from: firstRoute.to, to: firstRoute.from },
          ];
        }
        return [{ from: firstRoute.from, to: firstRoute.to }];
      }
      return [];
    }
    if (this.searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
      return this.routes.map((route) => ({ from: route.from, to: route.to }));
    }
    return [];
  }

  get areDatesValid() {
    const now = moment();
    const firstRoute = this.routes[0];
    const lastRoute = this.routes[this.routes.length - 1];
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      // если конечная дата раньше сегодняшнего дня
      if (
        firstRoute.dates?.endDate !== undefined &&
        moment(firstRoute.dates?.endDate).isBefore(now, 'days')
      ) {
        return false;
      }
      // если начальная дата раньше сегодняшнего дня
      if (moment(firstRoute.dates?.startDate).isBefore(now, 'days')) {
        return false;
      }
      // или в сложном маршруте начальная или конечная дата раньше сегодняшнего дня
    } else if (
      moment(firstRoute.dates?.startDate).isBefore(now) ||
      moment(lastRoute.dates?.startDate).isBefore(now)
    ) {
      return false;
    }
    return true;
  }

  get summaryString() {
    const DATE_FORMAT = 'D MMM';
    const firstRoute = this.routes[0];
    const lastRoute = this.routes[this.routes.length - 1];
    const pieces = [];
    // Аэропорты
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      pieces.push(
        `${firstRoute.from.airportCode} - ${firstRoute.to.airportCode}`,
      );
    } else if (this.searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
      pieces.push(
        `${firstRoute.from.airportCode} ... ${lastRoute.to.airportCode}`,
      );
    }

    // Даты
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      if (firstRoute.dates.endDate !== undefined) {
        pieces.push(
          `${firstRoute.dates.startDate.format(
            DATE_FORMAT,
          )} - ${firstRoute.dates.endDate.format(DATE_FORMAT)}`,
        );
      } else {
        pieces.push(`${firstRoute.dates.startDate.format(DATE_FORMAT)}`);
      }
    } else {
      pieces.push(
        `${firstRoute.dates.startDate.format(
          DATE_FORMAT,
        )} - ${lastRoute.dates.startDate.format(DATE_FORMAT)}`,
      );
    }

    // Пассажиры
    const totalPassengersCount = Object.entries(this.passengersCount).reduce(
      (total, [, count]) => total + count,
      0,
    );
    pieces.push(
      `${totalPassengersCount} ${i18n.t('common:passengers', {
        count: totalPassengersCount,
      })}`,
    );

    // Класс обслуживания
    const selectedFlightClass = this.flightClass;
    if (selectedFlightClass === 'ECONOM') {
      pieces.push(i18n.t('common:economy'));
    } else {
      pieces.push(i18n.t(`common:${selectedFlightClass.toLowerCase()}`));
    }

    return pieces.join(', ');
  }

  get searchJson() {
    const FLIGHT_TYPE = 'NOT_DIRECT';
    const formatRoutesAndRouteType = (routes, searchMode) => {
      const formatDate = (date) => date.format('YYYY-MM-DD');
      let routeType;
      let routesObj = [];
      const firstRoute = routes[0];
      if (searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
        if (firstRoute.dates.endDate !== undefined) {
          routeType = 'ROUND_TRIP';
        } else {
          routeType = 'ONE_WAY';
        }
      } else if (searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
        if (routes.length === 1) {
          routeType = 'ONE_WAY';
        } else {
          routeType = 'SEVERAL_WAYS';
        }
      }
      if (searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
        if (firstRoute.dates.startDate !== undefined) {
          routesObj.push({
            beginLocation: firstRoute.from.airportCode,
            endLocation: firstRoute.to.airportCode,
            date: formatDate(firstRoute.dates.startDate),
          });
        }
        if (firstRoute.dates.endDate !== undefined) {
          routesObj.push({
            beginLocation: firstRoute.to.airportCode,
            endLocation: firstRoute.from.airportCode,
            date: formatDate(firstRoute.dates.endDate),
          });
        }
      } else if (searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
        routesObj = routes.map((route) => ({
          beginLocation: route.from.airportCode,
          endLocation: route.to.airportCode,
          date: formatDate(route.dates.startDate),
        }));
      }
      return {
        routeType,
        routes: routesObj,
      };
    };
    const formatFlightClass = (flightClass) => flightClass;
    const formatSeats = (passengersCount) => [
      {
        key: 'ADULT',
        value: passengersCount.adults,
      },
      {
        key: 'CHILD',
        value: passengersCount.children,
      },
      {
        key: 'INFANT',
        value: passengersCount.infants,
      },
    ];

    if (userStore.isAuthenticated) {
      return {
        ...formatRoutesAndRouteType(this.routes, this.searchMode),
        flightType: FLIGHT_TYPE,
        seats: formatSeats(this.passengersCount),
        serviceClass: formatFlightClass(this.flightClass),
      };
    }
    return {
      ...formatRoutesAndRouteType(this.routes, this.searchMode),
      flightType: FLIGHT_TYPE,
      seats: formatSeats(this.passengersCount),
      serviceClass: formatFlightClass(this.flightClass),
    };
  }

  setEditedRoute(route) {
    // Задаём редактируемый маршрут
    this.editedRoute = route;
    // После того как маршрут поменялся, нужно
    // посчитать ограничения: какие даты пользователь
    // при редактировании этого маршрута может выбирать
    this._calculateEditedRouteDateConstraints(route);
  }

  _calculateEditedRouteDateConstraints(route) {
    // Задаём ограничения: дата перелёта должна быть не раньше,
    // чем у всех предыдущих перелётов, но не позже, чем у последующих
    const index = this.routes.indexOf(route);
    let minDate = moment();
    let maxDate = moment().add(12, 'months');
    if (this.searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
      this.routes.slice(0, index).forEach((r) => {
        if (r.dates && r.dates.startDate && r.dates.startDate > minDate) {
          minDate = r.dates.startDate;
        }
      });
      this.routes.slice(index + 1).forEach((r) => {
        if (r.dates && r.dates.startDate && r.dates.startDate < maxDate) {
          maxDate = r.dates.startDate;
        }
      });
    }
    this.datesConstrains.minDate = minDate;
    this.datesConstrains.maxDate = maxDate;
  }

  removeInvalidDates() {
    // проверка для ситуаций, когда пользователь ввёл даты сложного маршрута,
    // а потом вернулся в простой и изменил дату вылета на более позднюю:
    // если выбран простой маршрут, то даты вылета у всех перелётов,
    // которые раньше даты вылета первого перелёта, становятся undefined
    if (this.searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE) {
      this.routes.forEach((r) => {
        if (
          r.dates &&
          r.dates.startDate &&
          r.dates.startDate < this.routes[0].dates.startDate
        ) {
          // eslint-disable-next-line
          r.dates.startDate = undefined;
        }
      });
    }
  }

  updateEditedRoute(route) {
    Object.assign(this.editedRoute, route);
    this._persistParams();
  }

  setRoutes(routes) {
    this.routes = routes;
    this._persistParams();
  }

  setSearchMode(mode) {
    this.searchMode = mode;
  }

  editLocationOnRoute(location, route) {
    this.editedRoute = route;
    this.editedLocationField = location;
  }

  autocompleteLocations(route) {
    // пункт назначения текущего перелёта = пункт вылета следующего перелёта;
    const index = this.routes.indexOf(route);
    if (route.to && index + 1 < this.routes.length) {
      this.routes[index + 1].from = route.to;
    }
  }

  getEditedLocationField() {
    return this.editedLocationField;
  }

  setEditedLocation(location) {
    this.editedRoute[this.editedLocationField] = location;
    this._persistParams();
  }

  setShouldAutoOpenCalendar(val) {
    this.shouldAutoOpenCalendar = val;
  }

  reverseLocationsOnRoute(route) {
    const { from, to } = route;
    // eslint-disable-next-line
    route.to = from;
    // eslint-disable-next-line
    route.from = to;
    this._persistParams();
  }

  setPassengersCount(count) {
    this.passengersCount = count;
    this._persistParams();
  }

  setFlightClass(klass) {
    this.flightClass = klass;
    this._persistParams();
  }

  _resolveLocationsForFlights(flights) {
    return Promise.all(
      flights.map(
        (flight) =>
          new Promise((resolve) => {
            api.Avia.getLocationByCode(flight.from).then((result) => {
              // eslint-disable-next-line
              flight.from = processLocations([result])[0];
              // eslint-disable-next-line
              api.Avia.getLocationByCode(flight.to).then((result) => {
                // eslint-disable-next-line
                flight.to = processLocations([result])[0];
                resolve(flight);
              });
            });
          }),
      ),
    );
  }

  resolveParamsFromInfoString(str) {
    return new Promise((resolve) => {
      this.infoResolveInProgress = true;
      const flightsInfo = parseFlightInfoString(str);
      this._resolveLocationsForFlights(flightsInfo.flights).then(
        action((flights) => {
          // Обработка полученных данных
          this.routes = flights.map((flight) => ({
            to: flight.to,
            from: flight.from,
            dates: {
              startDate: flight.date,
            },
          }));
          // Если перелетов вообще нет
          if (this.routes.length === 0) {
            // Добавляем один пустой
            this.routes = [{}];
          }
          // Выбор режима формы поиска
          const { routes } = this;
          if (routes.length <= 1) {
            this.searchMode = SEARCH_FORM_MODES.SIMPLE_ROUTE;
          } else if (routes.length === 2) {
            // Если перелёта 2, то возможно их стоит переформатировать
            // в 1 перелёт туда-обратно
            const firstRoute = routes[0];
            const lastRoute = routes[1];
            if (
              firstRoute.from.airportCode === lastRoute.to.airportCode &&
              firstRoute.to.airportCode === lastRoute.from.airportCode
            ) {
              this.routes = [
                {
                  ...firstRoute,
                  dates: {
                    startDate: firstRoute.dates.startDate,
                    endDate: lastRoute.dates.startDate,
                  },
                },
              ];
              this.searchMode = SEARCH_FORM_MODES.SIMPLE_ROUTE;
            } else {
              this.searchMode = SEARCH_FORM_MODES.COMPLEX_ROUTE;
            }
          } else {
            this.searchMode = SEARCH_FORM_MODES.COMPLEX_ROUTE;
          }
          // Количество пассажиров
          this.passengersCount = {
            adults: flightsInfo.passengerCount.adult,
            children: flightsInfo.passengerCount.child,
            infants: flightsInfo.passengerCount.infant,
          };
          // Класс перелёта
          this.flightClass = flightsInfo.flightClass;
          // Всё готово
          this.infoResolveInProgress = false;
          resolve();
        }),
      );
    });
  }

  resolveParamsFromSearchObj(searchObj) {
    let str = '';
    searchObj.route.forEach((r) => {
      str += `${r.departureLocation}${moment(r.departureDate).format(
        'DDMMYYYY',
      )}${r.arrivalLocation}`;
    });
    str += searchObj.serviceClass[0].toLowerCase();
    str += `${searchObj.countAdult}${searchObj.countChild}${searchObj.countInfant}`;
    return this.resolveParamsFromInfoString(str);
  }

  removeEmptyRoutes() {
    this.routes = this.routes.filter(
      (route) =>
        route.dates !== undefined &&
        route.from !== undefined &&
        route.to !== undefined,
    );
  }
}

decorate(SearchStore, {
  routes: observable,
  editedRoute: observable,
  searchMode: observable,
  passengersCount: observable,
  flightClass: observable,
  infoResolveInProgress: observable,
  datesConstrains: observable,
  shouldAutoOpenCalendar: observable,
  totalPassengersCount: computed,
  inputErrors: computed,
  isInputValid: computed,
  searchJson: computed,
  setRoutes: action,
  setEditedRoute: action,
  removeInvalidDates: action,
  updateEditedRoute: action,
  setSearchMode: action,
  editLocationOnRoute: action,
  setEditedLocation: action,
  autocompleteLocations: action,
  reverseLocationsOnRoute: action,
  setPassengersCount: action,
  setFlightClass: action,
  setShouldAutoOpenCalendar: action,
  resolveParamsFromInfoString: action,
  removeEmptyRoutes: action,
});

export default new SearchStore();
