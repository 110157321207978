import debounce from 'debounce';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../common/SearchBar';
import { Modal, ModalHeader, ModalBody } from '../../../common/Modal';
import SearchResultsList from './SearchResultsList';
import { useStores } from '../../../../hooks/useStores';

const SEARCH_DEBOUNCE_WAIT = 500;
const INPUT_ID = 'location-input';

const TmpBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  height: 100px;
`;

const debouncedLoadLocations = debounce(
  (store, query) => store.loadLocations(query),
  SEARCH_DEBOUNCE_WAIT,
);

const LocationSearch = observer(() => {
  const { t } = useTranslation('search');
  const { locationSearchStore, searchStore } = useStores();
  const [query, setQuery] = useState('');
  useEffect(() => document.getElementById(INPUT_ID).focus());
  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.key === 'Escape') {
        window.history.back();
        searchStore.setShouldAutoOpenCalendar(true);
      }
    }
    document.addEventListener('keyup', handleEscapeKey);
    return () => {
      document.removeEventListener('keyup', handleEscapeKey);
    };
  });
  return (
    <div>
      <TmpBg />
      <Modal
        showCloseIcon
        onCloseRequest={() => {
          searchStore.setShouldAutoOpenCalendar(true);
          window.history.back();
        }}
      >
        <ModalHeader style={{ padding: '4px 54px 4px 15px' }}>
          <SearchBar
            inputId={INPUT_ID}
            autoComplete={false}
            onChange={(newQuery) => {
              debouncedLoadLocations(locationSearchStore, newQuery);
              setQuery(newQuery);
            }}
            value={query}
            placeholder={t('locationPlaceholder')}
          />
        </ModalHeader>
        <ModalBody>
          <SearchResultsList
            initialSuggestions={locationSearchStore.getInitialSuggestions(
              searchStore.getEditedLocationField(),
            )}
            results={locationSearchStore.locations}
            showInitialSuggestions={query === ''}
            onResultClick={(result) => {
              locationSearchStore.onLocationSelect(
                result,
                searchStore.getEditedLocationField(),
              );
              locationSearchStore.clearLocations();
              searchStore.setEditedLocation(result);
              searchStore.autocompleteLocations(searchStore.editedRoute);
              searchStore.setShouldAutoOpenCalendar(true);
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
});

export default LocationSearch;
