import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import TicketsIcon from './icons/TicketsIcon';
import WarningIcon from './icons/WarningIcon';
import SeatsIcon from './icons/SeatsIcon';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import { ReactComponent as TickIcon } from '../../assets/images/icons/tick.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import Button from './Button';
import { formatPrice } from '../../util';

const TIMEOUT_ERROR_CODE = 210;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: absolute;
  height: 412px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
`;

const TicketsIconContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const PopupText = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding: 0 20px;
  color: ${(props) => props.theme.colors.textColorLighter};
`;

const ButtonsFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
`;

const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  width: 44px;
  height: 44px;
  right: 10px;
  top: 7px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
`;

const ResultPopupContainer = styled.div`
  position: absolute;
  height: 412px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
`;

const WarningIconContainer = styled.div`
  margin-top: 37px;
  margin-bottom: 1px;
`;

const ClockIconContainer = styled.div`
  margin-top: 37px;
  margin-bottom: 25px;
`;

const SeatsIconContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 29px;
`;

const PriceChangedTextContainer = styled.div`
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
`;

const OldPriceText = styled.span`
  color: #b0a899;
`;

const NewPriceText = styled.span`
  color: ${(props) => props.theme.colors.primaryColor};
`;

const PriceChangedText = (props) => {
  const { oldPrice, newPrice } = props;
  return (
    <PriceChangedTextContainer>
      <OldPriceText>{formatPrice(oldPrice, 'RUB')} → </OldPriceText>
      <NewPriceText>{formatPrice(newPrice, 'RUB')}</NewPriceText>
    </PriceChangedTextContainer>
  );
};

const PriceChangedPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, oldPrice, newPrice, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <ResultPopupContainer>
          <TicketsIconContainer>
            <TicketsIcon />
          </TicketsIconContainer>
          <PopupText>{t('priceChanged')}</PopupText>
          <PriceChangedText oldPrice={oldPrice} newPrice={newPrice} />
          <ButtonsFooter>
            <Button
              variant="secondary"
              style={{ paddingBottom: 0 }}
              onClick={() => handleClick('back')}
            >
              {t('backToSearch')}
            </Button>
            <Button variant="primary" onClick={() => handleClick('continue')}>
              {t('buyFor', { price: formatPrice(newPrice, 'RUB') })}
            </Button>
          </ButtonsFooter>
        </ResultPopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const NoSeatsPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <ResultPopupContainer>
          <SeatsIconContainer>
            <SeatsIcon />
          </SeatsIconContainer>
          <PopupText>{t('didntConfirm')}</PopupText>
          <ButtonsFooter>
            <Button variant="primary" onClick={() => handleClick('back')}>
              {t('backToSearch')}
            </Button>
          </ButtonsFooter>
        </ResultPopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const GenericErrorPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick, code } = props;
  if (show) {
    return (
      <Overlay>
        <ResultPopupContainer>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <PopupText>
            {code === TIMEOUT_ERROR_CODE
              ? t('timeoutErrorMsg')
              : t('genericErrorMsg')}
          </PopupText>
          <ButtonsFooter>
            <Button variant="primary" onClick={() => handleClick('back')}>
              {t('goBack')}
            </Button>
          </ButtonsFooter>
        </ResultPopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const DatesErrorPopup = (props) => {
  const { t } = useTranslation(['searchResults', 'common']);
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <ResultPopupContainer>
          <ClockIconContainer>
            <ClockIcon />
          </ClockIconContainer>
          <PopupText>{t('searchResults:errorDates')}</PopupText>
          <ButtonsFooter>
            <Button variant="primary" onClick={() => handleClick('back')}>
              {t('common:goBack')}
            </Button>
          </ButtonsFooter>
        </ResultPopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const BookingRequestSuccessPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, onBackToSearchClick } = props;
  if (show) {
    return (
      <Overlay>
        <PopupContainer>
          <TicketsIconContainer>
            <TicketsIcon />
          </TicketsIconContainer>
          <PopupText>{t('requestSent')}</PopupText>
          <ButtonsFooter>
            <Button variant="primary" onClick={onBackToSearchClick}>
              {t('backToSearch')}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const BookingMethodPopup = (props) => {
  const { t } = useTranslation('common');
  const {
    show,
    onCloseClick,
    onSelfBookingClick,
    onAssistantBookingClick,
  } = props;
  if (show) {
    return (
      <Overlay onClick={onCloseClick}>
        <PopupContainer>
          <CloseBtn onClick={onCloseClick}>
            <CrossIcon />
          </CloseBtn>
          <TicketsIconContainer>
            <TicketsIcon />
          </TicketsIconContainer>
          <PopupText>{t('chooseReservationMethod')}</PopupText>
          <ButtonsFooter>
            <Button
              variant="secondary"
              style={{ paddingBottom: 0 }}
              onClick={onSelfBookingClick}
            >
              {t('manually')}
            </Button>
            <Button variant="primary" onClick={onAssistantBookingClick}>
              {t('viaAssistant')}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const PaymentIntentPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <PopupContainer>
          <TicketsIconContainer>
            <TicketsIcon />
          </TicketsIconContainer>
          <PopupText>{t('returnToPaymentMsg')}</PopupText>
          <ButtonsFooter>
            <Button
              variant="primary"
              style={{ paddingBottom: 0 }}
              onClick={() => handleClick('payment')}
            >
              {t('returnToPayment')}
            </Button>
            <Button variant="secondary" onClick={() => handleClick('search')}>
              {t('backToSearch')}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const StaleBookingDataPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <PopupContainer>
          <TicketsIconContainer>
            <TicketsIcon />
          </TicketsIconContainer>
          <PopupText>{t('staleFlightDataMsg')}</PopupText>
          <ButtonsFooter>
            <Button
              variant="primary"
              style={{ paddingBottom: 0 }}
              onClick={() => handleClick('back')}
            >
              {t('backToSearch')}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const SuccessPopup = (props) => {
  const { children, show, goBackText, onGoBackClick } = props;
  if (show) {
    return (
      <Overlay>
        <PopupContainer>
          <TicketsIconContainer>
            <TickIcon width={121} height={121} />
          </TicketsIconContainer>
          <PopupText>{children}</PopupText>
          <ButtonsFooter>
            <Button onClick={onGoBackClick} variant="primary">
              {goBackText}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const PassengerIncompletePopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <PopupContainer>
          <TicketsIconContainer>
            <TickIcon width={121} height={121} />
          </TicketsIconContainer>
          <PopupText>{t('moreDataRequired')}</PopupText>
          <ButtonsFooter>
            <Button onClick={() => handleClick('edit')} variant="primary">
              {t('specify')}
            </Button>
            <Button onClick={() => handleClick('back')} variant="secondary">
              {t('back')}
            </Button>
          </ButtonsFooter>
        </PopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

const BookingCanceledPopup = (props) => {
  const { t } = useTranslation('common');
  const { show, handleClick } = props;
  if (show) {
    return (
      <Overlay>
        <ResultPopupContainer>
          <SeatsIconContainer>
            <SeatsIcon />
          </SeatsIconContainer>
          <PopupText>{t('bookingDetails:bookingTimeout')}</PopupText>
          <ButtonsFooter>
            <Button variant="primary" onClick={() => handleClick('back')}>
              {t('backToSearch')}
            </Button>
          </ButtonsFooter>
        </ResultPopupContainer>
      </Overlay>
    );
  }
  return <div />;
};

export {
  PaymentIntentPopup,
  GenericErrorPopup,
  DatesErrorPopup,
  NoSeatsPopup,
  BookingMethodPopup,
  BookingRequestSuccessPopup,
  SuccessPopup,
  PassengerIncompletePopup,
  PriceChangedPopup,
  StaleBookingDataPopup,
  BookingCanceledPopup,
};
