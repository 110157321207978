import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import ScrollCalendar from 'react-scroll-calendar';
import 'react-scroll-calendar/build/react-scroll-calendar.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import Button from '../../../common/Button';
import { Modal, ModalHeader, ModalBody } from '../../../common/Modal';
import DatesStyles from './styles';
import { SEARCH_FORM_MODES } from '../../../../constants';
import { useStores } from '../../../../hooks/useStores';

const TmpBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  height: 100px;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const CalendarContainer = styled.div`
  height: 80vh;
  overflow-y: scroll;
`;

const StickyFooter = styled.div`
  position: fixed;
  background: #fff;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 8px 0;
`;

const NoReturnTicketLabel = styled.div`
  color: ${(props) => props.theme.colors.primaryColor};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
  height: 70px;
  width: 100%;
`;

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

const DateSelection = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('search');
  const { searchStore } = useStores();
  const { editedRoute, searchMode, datesConstrains } = searchStore;
  let dates;
  // Если форма поиска в режиме "сложный маршрут" и
  // уже выбраны были обе даты (туда-обратно) в режиме "простой маршрут"
  // то мы показываем только первую, а вторую отбрасываем
  if (editedRoute !== undefined && editedRoute.dates !== undefined) {
    if (searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE) {
      dates = {
        startDate: editedRoute.dates.startDate,
        endDate: undefined,
      };
    } else {
      // eslint-disable-next-line
      dates = editedRoute.dates;
    }
  }
  const routeUndefined = editedRoute === undefined;
  const bothRoutesSelected =
    !routeUndefined &&
    dates !== undefined &&
    dates.startDate !== undefined &&
    dates.endDate !== undefined;
  const shouldRedirectToSearch = routeUndefined;
  if (shouldRedirectToSearch) {
    return <Redirect to="/search" />;
  }
  const startDateOnlySelected =
    dates !== undefined &&
    dates.startDate !== undefined &&
    dates.endDate === undefined;
  return (
    <div>
      <DatesStyles />
      <TmpBg />
      <Modal showCloseIcon onCloseRequest={() => history.push('/search')}>
        <ModalHeader>
          <HeaderText>{t('flightDates')}</HeaderText>
        </ModalHeader>
        <ModalBody>
          <CalendarContainer>
            <ScrollCalendar
              minDate={datesConstrains.minDate}
              maxDate={datesConstrains.maxDate}
              renderMonthHeader={({ date }) => (
                <div>
                  {capitalize(
                    moment(date)
                      .locale(i18n.language)
                      .format('MMMM YYYY'),
                  )}
                </div>
              )}
              renderDay={({
                isInSelectedPeriod,
                isActive,
                isDisabled,
                currentValue,
              }) => {
                const className = `${
                  isInSelectedPeriod ? ' active-period' : ''
                }${isActive ? ' active' : ''}${isDisabled ? ' disabled' : ''}`;
                let activePeriodOverlayExtraClass = '';
                if (
                  dates !== undefined &&
                  !moment.isMoment(dates) &&
                  moment.isMoment(dates.endDate)
                ) {
                  activePeriodOverlayExtraClass = dates.endDate.isSame(
                    currentValue,
                  )
                    ? ' end'
                    : '';
                }
                return (
                  <div className={`day${className}`}>
                    {isActive && <div className="active-overlay" />}
                    {isInSelectedPeriod && (
                      <div
                        className={`active-period-overlay${activePeriodOverlayExtraClass}`}
                      />
                    )}
                    <span className="day-text">{currentValue.format('D')}</span>
                  </div>
                );
              }}
              weekStartsOnMonday
              periodSelection={searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE}
              onSelect={(val) =>
                searchStore.updateEditedRoute({
                  dates: {
                    startDate:
                      val.startDate !== undefined
                        ? val.startDate.locale(i18n.language)
                        : val.startDate,
                    endDate:
                      val.endDate !== undefined
                        ? val.endDate.locale(i18n.language)
                        : val.endDate,
                  },
                })
              }
              value={dates}
              className="avia-calendar"
            />
          </CalendarContainer>
        </ModalBody>
        <StickyFooter>
          <Link to="/search" onClick={() => searchStore.removeInvalidDates()}>
            {startDateOnlySelected &&
              searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE && (
                <NoReturnTicketLabel>{t('noReturnTicket')}</NoReturnTicketLabel>
              )}
            {(bothRoutesSelected ||
              (startDateOnlySelected &&
                searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE)) && (
              <Button>{t('done')}</Button>
            )}
          </Link>
        </StickyFooter>
      </Modal>
    </div>
  );
});

export default DateSelection;
