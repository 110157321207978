import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/useStores';
import { POPUP_TYPES } from '../../constants';
import {
  PassengerIncompletePopup,
  GenericErrorPopup,
  DatesErrorPopup,
  NoSeatsPopup,
  PriceChangedPopup,
  PaymentIntentPopup,
  StaleBookingDataPopup,
  BookingCanceledPopup,
} from './Popups';

const POPUP_TYPE_COMPONENT_MAP = {
  [POPUP_TYPES.PASSENGER_INCOMPLETE]: PassengerIncompletePopup,
  [POPUP_TYPES.GENERIC_ERROR]: GenericErrorPopup,
  [POPUP_TYPES.DATES_ERROR]: DatesErrorPopup,
  [POPUP_TYPES.NO_SEATS]: NoSeatsPopup,
  [POPUP_TYPES.PRICE_CHANGED]: PriceChangedPopup,
  [POPUP_TYPES.RETURN_TO_PAYMENT]: PaymentIntentPopup,
  [POPUP_TYPES.STALE_DATA_ERROR]: StaleBookingDataPopup,
  [POPUP_TYPES.BOOKING_CANCELLED]: BookingCanceledPopup,
};

const PopupsHost = observer(() => {
  const { popupsStore } = useStores();
  const { type, props: popupProps, visible, resolve } = popupsStore;
  const PopupComponent = POPUP_TYPE_COMPONENT_MAP[type];
  if (PopupComponent !== undefined && visible) {
    return (
      <PopupComponent
        show={visible}
        handleClick={(result) => {
          popupsStore.closePopup();
          resolve(result);
        }}
        {...popupProps}
      />
    );
  }
  return null;
});

export default PopupsHost;
