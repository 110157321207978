import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FLIGHT_CLASSES } from './constants';

const CURRENCIES_MAP = {
  RUB: '₽',
  USD: '$',
  EUR: '€',
};

const formatPrice = (price, currency) =>
  `${price} ${CURRENCIES_MAP[currency]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const formatMinutes = (m) =>
  [[Math.floor(m / 60), 'ч'], [m % 60, 'м']]
    .map(([number, string]) => (number === 0 ? '' : number.toString() + string))
    .join(' ')
    .trim();

function filterUndefinedEmptyStrings(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line
      ([_, fieldValue]) => fieldValue !== '' && fieldValue !== undefined,
    ),
  );
}

function historyGoBack() {
  window.history.back();
}

function addQueryParamsToUrl(url, params) {
  let finalUrl = url;
  if (params !== undefined) {
    const paramsStr = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const urlObj = new URL(url);
    if (urlObj.search === '') {
      finalUrl += `?${paramsStr}`;
    } else {
      finalUrl += `&${paramsStr}`;
    }
  }
  return finalUrl;
}

function useRedirectToAuth() {
  const returnUrl = window.location.href;
  const returnUrlEncoded = window.encodeURIComponent(returnUrl);
  const authUrl = `/auth?return_to=${returnUrlEncoded}`;
  const history = useHistory();
  const redirect = () => {
    history.push(authUrl);
  };
  return redirect;
}

const flightToRoutes = (flight) => {
  const routes = [];
  const segmentsCount = Object.values(flight.connectedSegmentsCount).map(
    (val) => val + 1,
  );
  let currentIdx = 0;
  segmentsCount.forEach((count) => {
    const segments = flight.segments
      .slice(currentIdx, currentIdx + count)
      .map((segment) => ({ ...segment, type: 'FLIGHT' }));
    const routeDuration = segments
      .map((segment) => segment.travelDuration * 1)
      .reduce((duration, total) => duration + total, 0);
    const segmentsWithConnections = [];
    segments.forEach((segment, index) => {
      if (index > 0) {
        const prevSegment = segments[index - 1];
        segmentsWithConnections.push({
          type: 'CONNECTION',
          location: prevSegment.endLocation,
          duration: moment(segment.beginDate).diff(
            moment(prevSegment.endDate),
            'minutes',
          ),
        });
      }
      segmentsWithConnections.push(segment);
    });
    routes.push({
      segments: segmentsWithConnections,
      routeDuration,
    });
    currentIdx += count;
  });
  return routes;
};

function hexToRgba(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${opacity})`
    : null;
}

function parseFlightInfoString(str) {
  // Направления перелётов
  const flights = [];
  const flightRegex = /([A-Z]{3})(\d{8})([A-Z]{3})/;
  let currentStr = str;
  let moreFlights = true;
  while (moreFlights) {
    const groups = currentStr.match(flightRegex);
    if (groups !== null) {
      flights.push({
        from: groups[1],
        date: moment(groups[2], 'DDMMYYYY'),
        to: groups[3],
      });
      currentStr = currentStr.substring(groups[0].length);
    }
    moreFlights = currentStr[0] === currentStr[0].toUpperCase();
  }
  // Класс перелёта
  const flightClassEnc = currentStr[0];
  let flightClass;
  switch (flightClassEnc) {
    case 'e':
      flightClass = FLIGHT_CLASSES.ECONOMY;
      break;
    case 'b':
      flightClass = FLIGHT_CLASSES.BUSINESS;
      break;
    case 'f':
      flightClass = FLIGHT_CLASSES.FIRST;
      break;
    default:
      // eslint-disable-next-line
      console.error('error');
      break;
  }
  const passengerCount = {
    adult: parseInt(currentStr[1], 10),
    child: parseInt(currentStr[2], 10),
    infant: parseInt(currentStr[3], 10),
  };
  return {
    passengerCount,
    flightClass,
    flights,
  };
}

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

function parseQueryVars() {
  const result = {};
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return result;
}

const isEqual = (a, b) => {
  if (a instanceof moment && b instanceof moment) {
    return a.diff(b, 'minutes') === 0;
  }
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    return a === b;
  }
  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }
  return keys.every((k) => isEqual(a[k], b[k]));
};

export {
  formatPrice,
  formatMinutes,
  filterUndefinedEmptyStrings,
  historyGoBack,
  flightToRoutes,
  hexToRgba,
  parseFlightInfoString,
  capitalize,
  useRedirectToAuth,
  parseQueryVars,
  addQueryParamsToUrl,
  isEqual,
};
