import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from '../../../assets/images/icons/seats.svg';

const Container = styled.span`
  svg {
    .seats-stroke-primary {
      stroke: ${(props) => props.theme.colors.iconsPrimaryColor};
    }

    .seats-fill-secondary {
      fill: ${(props) => props.theme.colors.iconsSecondaryColor};
    }
  }
`;

const SeatsIcon = () => (
  <Container>
    <Icon />
  </Container>
);

export default SeatsIcon;
