import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SearchStore from '../../../stores/searchStore';
import DatePicker from './DatePicker';
import Button from '../../common/Button';
import {
  LoadingModal,
  LOADING_MODAL_VARIANTS,
} from '../../common/LoadingModal';
import LocationsSelectorFullWidth from './LocationsSelectorFullWidth';
import PassengerSelector from './PassengerSelector';
import ComplexRouteSelector from './ComplexRouteSelector';
import { SEARCH_FORM_MODES } from '../../../constants';
import { useStores } from '../../../hooks/useStores';

const SHOW_SEARCH_MODE = false;

const SearchTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 13px;
  color: ${(props) => props.theme.colors.textColor};
`;

const DatePickersContainer = styled.div`
  height: 50px;
`;

const DatePickersSeparatorLine = styled.div`
  display: inline-block;
  width: 0.5px;
  height: 40px;
  margin: 5px 0;
  background-color: ${(props) => props.theme.colors.borderColor};
`;

const SearchFormOuterContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 345px;
  top: 56px;
`;

const SearchFormContainer = styled.div`
  bottom: 159px;
  width: 345px;
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 54, 108, 0.15);
  border-radius: 10px;
`;

const SeparatorLine = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.borderColor};
  width: 100%;
`;

const ToggleModeText = styled.div`
  cursor: pointer;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.textColorLight};
  text-align: center;
`;

const DatePickersFullWidth = (props) => {
  const { t } = useTranslation(['search']);
  const { route, errors } = props;
  const anyDates = route.dates !== undefined;
  const startDate = anyDates ? route.dates.startDate : undefined;
  const endDate = anyDates ? route.dates.endDate : undefined;
  return (
    <DatePickersContainer>
      <Link
        to="/search/dates"
        onClick={() => SearchStore.setEditedRoute(SearchStore.routes[0])}
      >
        <DatePicker
          error={errors && errors.startDate !== undefined}
          value={startDate}
          placeholder={t('there')}
        />
      </Link>
      <DatePickersSeparatorLine />
      <Link
        to="/search/dates"
        onClick={() => SearchStore.setEditedRoute(SearchStore.routes[0])}
      >
        <DatePicker
          error={errors && errors.endDate !== undefined}
          value={endDate}
          placeholder={t('return')}
        />
      </Link>
    </DatePickersContainer>
  );
};

const SearchForm = observer(() => {
  const history = useHistory();
  const { t } = useTranslation(['search', 'common']);
  const {
    searchStore,
    flightSearchStore,
    passengersStore,
    filterStore,
    commonStore,
  } = useStores();
  const { showFormErrors } = commonStore;
  const { searchMode, routes } = searchStore;
  useEffect(() => {
    if (!commonStore.searchPrefilled) {
      if (
        commonStore.initialQueryVars !== undefined &&
        commonStore.initialQueryVars.route
      ) {
        searchStore
          .resolveParamsFromInfoString(commonStore.initialQueryVars.route)
          .then(() => {
            passengersStore.syncPassengersWithSearch();
            commonStore.setSearchPrefilled(true);
          });
      }
    }
  }, [commonStore, passengersStore, searchStore]);
  const { inputErrors } = searchStore;
  const noErrors = Object.entries(inputErrors).length === 0;
  const formErrors = showFormErrors ? inputErrors.routes : undefined;
  return (
    <SearchFormOuterContainer>
      <LoadingModal
        text={t('common:loading')}
        variant={LOADING_MODAL_VARIANTS.CHECKING_PRICE}
        show={searchStore.infoResolveInProgress}
      />
      <SearchTitle>{t('title')}</SearchTitle>
      <div>
        <SearchFormContainer>
          {searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE && (
            <div>
              <LocationsSelectorFullWidth
                errors={formErrors ? formErrors[0] : {}}
                route={routes[0]}
              />
              <SeparatorLine />
              <DatePickersFullWidth
                errors={formErrors ? formErrors[0] : {}}
                route={routes[0]}
              />
              <SeparatorLine />
            </div>
          )}
          {searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE && (
            <div>
              <ComplexRouteSelector
                errors={formErrors}
                routes={routes}
                onChange={(newRoutes) => searchStore.setRoutes(newRoutes)}
              />
            </div>
          )}
          <Link to="/search/passengers">
            <PassengerSelector
              error={inputErrors.passengersCount !== undefined}
              flightClass={searchStore.flightClass}
              passengersCount={searchStore.passengersCount}
              placeholder={t('passengersAndClass')}
            />
          </Link>
          <SeparatorLine />
          <Button
            onClick={() => {
              if (noErrors) {
                searchStore.removeEmptyRoutes();
                filterStore.clearFilterParams();
                flightSearchStore.clearFlights();
                passengersStore.syncPassengersWithSearch();
                history.push(`/search/${searchStore.infoString}`);
              } else {
                commonStore.setShowFormErrors(true);
              }
            }}
          >
            {t('findTicketsBtn')}
          </Button>
        </SearchFormContainer>
      </div>
      {SHOW_SEARCH_MODE && (
        <ToggleModeText
          onClick={() =>
            searchStore.setSearchMode(
              searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE
                ? SEARCH_FORM_MODES.COMPLEX_ROUTE
                : SEARCH_FORM_MODES.SIMPLE_ROUTE,
            )
          }
        >
          {searchMode === SEARCH_FORM_MODES.SIMPLE_ROUTE && t('complexRoute')}
          {searchMode === SEARCH_FORM_MODES.COMPLEX_ROUTE && t('simpleRoute')}
        </ToggleModeText>
      )}
    </SearchFormOuterContainer>
  );
});

export default SearchForm;
