import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';

const ModalWrapper = styled.div`
  padding-top: 10px;
`;

const ModalContainer = styled.div`
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  padding: 5px 0;
  min-height: 100px;
`;

const ModalHeader = styled.div`
  padding: 4px 45px 4px 15px;
`;

const ModalBody = styled.div`
  padding-top: 18px;
  overflow: hidden;
`;

const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  width: 44px;
  height: 44px;
  right: 10px;
  top: 7px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
`;

const Modal = (props) => {
  const { showCloseIcon, onCloseRequest, children } = props;
  return (
    <ModalWrapper>
      <ModalContainer>
        {showCloseIcon && (
          <CloseBtn onClick={onCloseRequest}>
            <CrossIcon />
          </CloseBtn>
        )}
        <div>{children}</div>
      </ModalContainer>
    </ModalWrapper>
  );
};

export { Modal, ModalHeader, ModalBody };
