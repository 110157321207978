import { decorate, observable, action } from 'mobx';
import userStore from './userStore';
import api from '../api';

const POLLING_REQUEST_LIMIT = 2;
const POLLING_REQUEST_LIMIT_AFTER = 6;
const POLLING_INTERVAL = 10000;

const BOOKING_STATUS_CODES = {
  READY: 'BOOKING_COMPLETED',
  ERROR: 'BOOKING_ERROR',
  BOOKING_CANCELLED: 'BOOKING_CANCELLED',
};

const BOOKING_ERRORS = {
  NO_ERROR: -1,
  GENERIC_ERROR: 0,
  END_BOOKING_ERROR: 1,
  REQUEST_LIMIT_EXCEEDED: 2,
  BOOKING_CANCELLED: 3,
};

class BookingStore {
  bookingInfo;

  loadingError = BOOKING_ERRORS.NO_ERROR;

  _cache = {};

  _pollingInterval;

  showBookingCompleted = false;

  showNotification = false;

  wereCardsLoaded = false;

  loyaltyCards = [];

  setShowBookingCompleted(val) {
    this.showBookingCompleted = val;
  }

  setShowNotification(val) {
    this.showNotification = val;
  }

  downloadReceiptForPassenger(passengerIdx) {
    const { receiptId } = this.bookingInfo.passengers[passengerIdx];
    api.Avia.downloadReceipt(receiptId);
  }

  pollBookingInfo(id) {
    if (id === undefined) {
      this.loadingError = BOOKING_ERRORS.GENERIC_ERROR;
      return undefined;
    }
    if (userStore.isAuthenticated === false) {
      this.loadingError = BOOKING_ERRORS.GENERIC_ERROR;
      return undefined;
    }
    const { currentUser, token } = userStore;
    const requestData = {
      systemId: 'prime.avia.mobile',
      userId: currentUser.username,
      customerId: currentUser.username,
      userToken: token,
      obj: id * 1,
    };
    return new Promise((resolve, reject) => {
      if (this._cache[id] === undefined) {
        this._cache[id] = {
          pollsCount: 0,
          done: false,
          result: undefined,
        };
      }
      const cacheEntry = this._cache[id];
      if (cacheEntry.done) {
        if (cacheEntry.result === undefined) {
          resolve();
          return;
        }
        reject(cacheEntry.result);
        if (
          cacheEntry.result !== BOOKING_ERRORS.REQUEST_LIMIT_EXCEEDED ||
          (cacheEntry.result === BOOKING_ERRORS.REQUEST_LIMIT_EXCEEDED &&
            cacheEntry.pollsCount >=
              POLLING_REQUEST_LIMIT + POLLING_REQUEST_LIMIT_AFTER)
        ) {
          return;
        }
      }
      const poll = () => {
        api.Avia.getBookingInfo(requestData).then(
          action((response) => {
            this.bookingInfo = response;
            if (this.bookingInfo.state.code === BOOKING_STATUS_CODES.READY) {
              cacheEntry.done = true;
              this.bookingInfo.passengers.forEach((p, i) => {
                if (p.discountCardId && !this.wereCardsLoaded) {
                  this.getLoyaltyCardById(p.discountCardId, i);
                }
                this.wereCardsLoaded = true;
              });
              resolve();
              window.clearInterval(this._pollingInterval);
              this.showNotification = false;
              this.showBookingCompleted = true;
              return;
            }
            if (
              this.bookingInfo.state.code === BOOKING_STATUS_CODES.ERROR ||
              this.bookingInfo.state.code ===
                BOOKING_STATUS_CODES.BOOKING_CANCELLED
            ) {
              cacheEntry.done = true;
              switch (this.bookingInfo.state.code) {
                case BOOKING_STATUS_CODES.BOOKING_CANCELLED:
                  cacheEntry.result = BOOKING_ERRORS.BOOKING_CANCELLED;
                  reject(BOOKING_ERRORS.BOOKING_CANCELLED);
                  break;
                default:
                  cacheEntry.result = BOOKING_ERRORS.END_BOOKING_ERROR;
                  reject(BOOKING_ERRORS.END_BOOKING_ERROR);
              }

              window.clearInterval(this._pollingInterval);
              return;
            }
            if (this.bookingInfo.manualIntervention) {
              resolve();
              window.clearInterval(this._pollingInterval);
              this.showNotification = true;
              this.showBookingCompleted = false;
              return;
            }
            this.bookingInfo.passengers.forEach((p, i) => {
              if (p.discountCardId && !this.wereCardsLoaded) {
                this.getLoyaltyCardById(p.discountCardId, i);
              }
              this.wereCardsLoaded = true;
            });
            cacheEntry.pollsCount += 1;
            if (
              cacheEntry.pollsCount >=
              POLLING_REQUEST_LIMIT + POLLING_REQUEST_LIMIT_AFTER
            ) {
              window.clearInterval(this._pollingInterval);
              return;
            }
            if (cacheEntry.pollsCount >= POLLING_REQUEST_LIMIT) {
              cacheEntry.done = true;
              cacheEntry.result = BOOKING_ERRORS.REQUEST_LIMIT_EXCEEDED;
              reject(BOOKING_ERRORS.REQUEST_LIMIT_EXCEEDED);
            }
          }),
          action(() => {
            cacheEntry.done = true;
            cacheEntry.result = BOOKING_ERRORS.GENERIC_ERROR;
            reject(BOOKING_ERRORS.GENERIC_ERROR);
            window.clearInterval(this._pollingInterval);
          }),
        );
      };
      poll();
      this._pollingInterval = window.setInterval(poll, POLLING_INTERVAL);
    });
  }

  stopPolling() {
    window.clearInterval(this._pollingInterval);
  }

  getLoyaltyCardById(id, index) {
    api.Avia.getLoyaltyCardById(id, userStore.token).then(
      action((card) => {
        this.loyaltyCards[index] = { ...card };
      }),
    );
  }
}

decorate(BookingStore, {
  bookingInfo: observable,
  loadingError: observable,
  showBookingCompleted: observable,
  showNotification: observable,
  loyaltyCards: observable,
  startPollingBookingInfo: action,
  setShowBookingCompleted: action,
  setShowNotification: action,
});

export default new BookingStore();
export { BOOKING_ERRORS };
