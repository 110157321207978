/* global appConfig */

const AlfaTravelTheme = {
  colors: {
    borderColor: '#D7D7D7',
    borderColorLight: '#E2DFD9',
    textColor: '#505759',
    textColorLighter: '#6A665E',
    textColorLight: '#A7ABAC',
    textColorFlightPrice: '#EF3124',
    primaryColor: '#EF3124',
    inputLabelColor: '#A7ABAC',
    inputErrorColor: '#EF3124',
    disabledButtonColor: '#EEEBE5',
    shareLinkInputBgColor: '#F8F6F4',
    buttonColorLight: '#FBF7EF',
    iconsPrimaryColor: '#B0A899',
    iconsSecondaryColor: '#C6BEB3',
  },
};

const PrimeTheme = {
  colors: {
    borderColor: '#D7D7D7',
    borderColorLight: '#E2DFD9',
    textColor: '#2E1F08',
    textColorLighter: '#6A665E',
    textColorLight: '#A6A6A6',
    textColorFlightPrice: '#00617F',
    primaryColor: '#9B7E4B',
    inputLabelColor: '#A7ABAC',
    inputErrorColor: '#EF3124',
    disabledButtonColor: '#EEEBE5',
    shareLinkInputBgColor: '#F8F6F4',
    buttonColorLight: '#FBF7EF',
    iconsPrimaryColor: '#B0A899',
    iconsSecondaryColor: '#C6BEB3',
  },
};

const themes = {
  'alfa-travel': AlfaTravelTheme,
  prime: PrimeTheme,
};

function selectTheme(appConfig) {
  const { theme } = appConfig;
  return themes[theme];
}

const DefaultTheme = selectTheme(appConfig);

export { DefaultTheme };
