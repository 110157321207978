import React from 'react';
import styled from '@emotion/styled';
import { hexToRgba } from '../../util';

const Container = styled.div`
  padding: 10px;
`;

const ButtonComponent = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.disabledButtonColor;
    }
    if (props.variant === 'primary') {
      return props.theme.colors.primaryColor;
    }
    if (props.variant === 'secondary') {
      return props.theme.colors.textColorLight;
    }
    if (props.variant === 'clear') {
      return '#FFF';
    }
    if (props.variant === 'primary-inverted') {
      return hexToRgba(props.theme.colors.primaryColor, 0.1);
    }
    return '#FFF';
  }};
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => {
    if (props.redText) {
      return '#a1330a';
    }
    if (props.disabled) {
      return props.theme.colors.textColorLight;
    }
    if (props.variant === 'clear') {
      return props.theme.colors.primaryColor;
    }
    if (props.variant === 'primary-inverted') {
      return props.theme.colors.primaryColor;
    }
    return '#FFF';
  }};
  text-align: center;
  width: 100%;
  height: 50px;
`;

const Button = (props) => {
  const {
    children,
    disabled,
    variant = 'primary',
    onClick,
    style,
    buttonStyle,
    redText,
  } = props;
  return (
    <Container style={style}>
      <ButtonComponent
        style={buttonStyle}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        redText={redText}
      >
        {children}
      </ButtonComponent>
    </Container>
  );
};

export default Button;
