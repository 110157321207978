import { decorate, observable, action } from 'mobx';
import commonStore from './commonStore';
import {
  DEV,
  DEFAULT_CUSTOMER_TYPE,
  DEV_ACCESS_TOKEN,
  USER_TYPES,
} from '../constants';
import api from '../api';

const TOKEN_LOCAL_STORAGE_KEY = 'AVIA_TOKEN';

class UserStore {
  token;

  currentUser;

  userType;

  authInProgress;

  contacts = { email: '', phone: '' };

  get isAuthenticated() {
    return this.currentUser !== undefined;
  }

  get userType() {
    if (this.isAuthenticated) {
      if (this.currentUser.profileType === 'CUSTOMER') {
        return USER_TYPES.CUSTOMER;
      }
      return USER_TYPES.ASSISTANT;
    }
    return USER_TYPES.CUSTOMER;
  }

  setContacts(value) {
    this.contacts = value;
  }

  async checkAuth() {
    let token;
    const { initialQueryVars } = commonStore;
    const queryToken = initialQueryVars.access_token;
    if (queryToken === undefined) {
      if (DEV.USE_TOKEN_FROM_CONSTANTS) {
        token = DEV_ACCESS_TOKEN;
      } else {
        token = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
      }
    } else {
      token = queryToken;
    }
    try {
      await this.authenticateWithToken(token);
    } catch (err) {
      // Аутентификация не удалась
    }
  }

  auth(login, password) {
    this.authInProgress = true;
    return new Promise((resolve, reject) => {
      let { customerType } = commonStore.initialQueryVars;
      if (customerType === undefined) {
        customerType = DEFAULT_CUSTOMER_TYPE;
      }
      api.Prime.auth(login, password, customerType).then(
        (response) => {
          if (response.result !== undefined) {
            this.authenticateWithToken(response.result).then(resolve, reject);
          } else {
            this.authInProgress = false;
            reject();
          }
        },
        () => {
          this.authInProgress = false;
          reject();
        },
      );
    });
  }

  authenticateWithToken(token) {
    this.authInProgress = true;
    return new Promise((resolve, reject) => {
      if (token === undefined || token === null) {
        this.authInProgress = false;
        reject();
        return;
      }
      api.Prime.me(token).then(
        action((user) => {
          this.token = token;
          this.currentUser = user;
          this.contacts.email = this.currentUser.email || '';
          this.contacts.phone = this.currentUser.phone || '';
          window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
          this.authInProgress = false;
          resolve();
        }),
        () => {
          this.authInProgress = false;
          reject();
        },
      );
    });
  }
}

decorate(UserStore, {
  currentUser: observable,
  contacts: observable,
  setContacts: action,
  authenticateWithToken: action,
});

export default new UserStore();
