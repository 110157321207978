import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ReactComponent as LocationIcon } from '../../../assets/images/icons/location-pin.svg';

const Container = styled.div`
  position: relative;
  cursor: pointer;
  padding: 15px 15px 15px 41px;
`;
const IconContainer = styled.div`
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
const Input = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 250px;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColor};
`;
const InputPlaceholder = styled(Input)`
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColorLight};
`;
const AirportCodeLabel = styled.div`
  padding-top: 2px;
  float: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  width: 35px;
  text-align: center;
  color: ${(props) => props.theme.colors.textColorLighter};
`;

const LocationInput = (props) => {
  const theme = useTheme();
  const { value, error, placeholder } = props;
  const hasInput = value !== undefined;
  return (
    <Container>
      <IconContainer>
        <LocationIcon fill={theme.colors.textColorLight} />
      </IconContainer>
      {hasInput && (
        <div>
          <Input>{value.cityName}</Input>
          <AirportCodeLabel>{value.airportCode}</AirportCodeLabel>
        </div>
      )}
      {!hasInput && (
        <InputPlaceholder error={error}>{placeholder}</InputPlaceholder>
      )}
    </Container>
  );
};

export default LocationInput;
