import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import EarthSpinner from '../../assets/images/icons/earth-spinner.gif';
import CardsIcon from './icons/CardsIcon';
import { ReactComponent as TicketsIcon } from '../../assets/images/icons/tickets.svg';

const LOADING_MODAL_VARIANTS = {
  CHECKING_PRICE: 0,
  REDIRECT_TO_PAYMENT: 1,
  PURCHASE_IN_PROGRESS: 2,
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
`;

const LoadingModalContainer = styled.div`
  position: absolute;
  width: 250px;
  height: 200px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
`;

const EarthIconContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 16px;
`;

const CardsIconContainer = styled.div`
  margin-top: 34px;
  margin-bottom: 16px;
`;

const TicketsIconContainer = styled.div`
  margin-top: 26px;
  margin-bottom: 16px;
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.textColorLighter};
`;

const LoadingModal = (props) => {
  const { t } = useTranslation('common');
  const { show, variant, text } = props;
  if (show) {
    return (
      <Overlay>
        <LoadingModalContainer>
          {variant === LOADING_MODAL_VARIANTS.CHECKING_PRICE && (
            <div>
              <EarthIconContainer>
                <img alt="" src={EarthSpinner} />
              </EarthIconContainer>
              <ModalText>
                {text === undefined && <span>{t('priceCheck')}</span>}
                {text !== undefined && <span>{text}</span>}
              </ModalText>
            </div>
          )}
          {variant === LOADING_MODAL_VARIANTS.REDIRECT_TO_PAYMENT && (
            <div>
              <CardsIconContainer>
                <CardsIcon />
              </CardsIconContainer>
              <ModalText>{t('paymentRedirect')}</ModalText>
            </div>
          )}
          {variant === LOADING_MODAL_VARIANTS.PURCHASE_IN_PROGRESS && (
            <div>
              <TicketsIconContainer>
                <TicketsIcon />
              </TicketsIconContainer>
              <ModalText>{t('purchaseInProgress')}</ModalText>
            </div>
          )}
        </LoadingModalContainer>
      </Overlay>
    );
  }
  return <div />;
};

export { LoadingModal, LOADING_MODAL_VARIANTS };
