import { decorate, observable, action } from 'mobx';
import cloneDeep from 'lodash.clonedeep';
import i18n from '../i18n';
import api from '../api';

const LOCAL_STORAGE_KEY = 'AviaInitialSuggestions-bg61g291jg';

const processLocations = (locations) =>
  locations.map((location) => {
    const { locationType } = location;
    return {
      airportName:
        locationType === 'CITY' ? i18n.t('common:anyAirport') : location.name,
      airportCode: location.code,
      cityName:
        locationType === 'CITY' ? location.name : location.parentLocation.name,
      countryName: location.country.nameShort,
    };
  });

class LocationSearchStore {
  _lastQuery;

  _popularLocations;

  _suggestions;

  locations;

  _persistInitialSuggestions() {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(this._suggestions),
    );
  }

  _loadPopularLocations() {
    api.Avia.getPopularLocations().then(
      action((popularLocations) => {
        const processedLocations = processLocations(popularLocations);
        this._suggestions = [
          cloneDeep(processedLocations),
          cloneDeep(processedLocations),
        ];
        this._persistInitialSuggestions();
      }),
    );
  }

  _loadInitialSuggestions() {
    const lsListStr = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lsListStr !== null) {
      try {
        const lsList = JSON.parse(lsListStr);
        this._suggestions = lsList;
      } catch {
        this._suggestions = [[], []];
      }
    } else {
      this._suggestions = [[], []];
    }
  }

  getInitialSuggestions(direction) {
    if (direction === 'from') {
      return this._suggestions[0];
    }
    if (direction === 'to') {
      return this._suggestions[1];
    }
    return undefined;
  }

  // direction - строка "from" или "to"
  onLocationSelect(location, direction) {
    let suggestions;
    if (direction === 'from') {
      suggestions = this._suggestions[0];
    } else if (direction === 'to') {
      suggestions = this._suggestions[1];
    }
    if (suggestions !== undefined) {
      // Пытаемся найти локацию в списке предложенных по IATA коду
      const suggestionsIdx = suggestions.findIndex(
        (s) => s.airportCode === location.airportCode,
      );
      // Если она там есть
      if (suggestionsIdx !== -1) {
        // Удаляем её из того места списка в котором она находится
        suggestions.splice(suggestionsIdx, 1);
        // Чтобы потом добавить её на первое место в списке
      } else {
        // Иначе удаляем последнюю локацию из списка
        suggestions.splice(suggestions.length - 1, 1);
      }
      // Добавляем выбранную локацию на первое место
      suggestions.unshift(location);
      // Сохраняем
      this._persistInitialSuggestions();
    }
  }

  initialize() {
    this._loadInitialSuggestions();
    if (
      this._suggestions[0].length === 0 &&
      this._suggestions[1].length === 0
    ) {
      this._loadPopularLocations();
    }
  }

  clearLocations() {
    this.locations = undefined;
  }

  loadLocations(query) {
    if (query !== '' && query !== undefined) {
      this._lastQuery = query;
      api.Avia.findLocation(query).then(
        action((locations) => {
          if (this._lastQuery === query) {
            if (locations !== null && locations !== '') {
              this.locations = processLocations(locations);
            } else {
              this.locations = [];
            }
          }
        }),
      );
    } else {
      this.locations = undefined;
    }
  }
}

decorate(LocationSearchStore, {
  locations: observable,
  loadLocations: action,
  clearLocations: action,
});

const locationSearchStore = new LocationSearchStore();

locationSearchStore.initialize();

export default locationSearchStore;

export { processLocations };
