import { toJS, decorate, observable, action, computed } from 'mobx';
import moment from 'moment';
import { diff } from 'deep-object-diff';
import clonedeep from 'lodash.clonedeep';
import i18n from '../i18n';

const DEFAULT_PARAMS = {};
const DEFAULT_CONSTRAINTS = {};

const JSON_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

function paramsDiff(obj1, obj2) {
  const diffResult = diff(obj1, obj2);
  if (diffResult.routes) {
    const routesConverted = Object.entries(diffResult.routes).map(
      ([idx, route]) => {
        const resultRoute = clonedeep(toJS(route));
        resultRoute.id = obj1.routes[idx].id;
        if (route.vendors !== undefined) {
          resultRoute.vendors = obj2.routes[idx].vendors;
        }
        return resultRoute;
      },
    );
    return { ...diffResult, routes: routesConverted };
  }
  return diffResult;
}

function jsonParamsToStr(params) {
  return encodeURIComponent(JSON.stringify(params));
}

function jsonParamsFromStr(str) {
  try {
    return JSON.parse(decodeURIComponent(str));
  } catch (e) {
    return undefined;
  }
}

function paramsToJson(params) {
  const result = clonedeep(toJS(params));
  if (result.orderBy) {
    result.sort = { code: result.orderBy };
    delete result.orderBy;
    delete result.orders;
  }
  if (params.routes) {
    result.routes = params.routes.map((route) => {
      const resultRoute = clonedeep(toJS(route));
      if (route.arrivalTime) {
        resultRoute.arrivalTime = {};
        if (route.arrivalTime.min) {
          resultRoute.arrivalTime.min = route.arrivalTime.min.format(
            JSON_DATE_FORMAT,
          );
        }
        if (route.arrivalTime.max) {
          resultRoute.arrivalTime.max = route.arrivalTime.max.format(
            JSON_DATE_FORMAT,
          );
        }
      }
      if (route.departureTime) {
        resultRoute.departureTime = {};
        if (route.departureTime.min) {
          resultRoute.departureTime.min = route.departureTime.min.format(
            JSON_DATE_FORMAT,
          );
        }
        if (route.departureTime.max) {
          resultRoute.departureTime.max = route.departureTime.max.format(
            JSON_DATE_FORMAT,
          );
        }
      }
      if (route.connectedSegmentsDuration) {
        resultRoute.connectedSegmentsDuration = {};
        if (route.connectedSegmentsDuration.min !== undefined) {
          resultRoute.connectedSegmentsDuration.min = (
            route.connectedSegmentsDuration.min * 60
          ).toString();
        }
        if (route.connectedSegmentsDuration.max !== undefined) {
          resultRoute.connectedSegmentsDuration.max = (
            route.connectedSegmentsDuration.max * 60
          ).toString();
        }
      }
      if (route.totalFlightDuration) {
        resultRoute.totalFlightDuration = {};
        if (route.totalFlightDuration.min) {
          resultRoute.totalFlightDuration.min =
            route.totalFlightDuration.min * 60;
        }
        if (route.totalFlightDuration.max) {
          resultRoute.totalFlightDuration.max =
            route.totalFlightDuration.max * 60;
        }
      }
      return resultRoute;
    });
  }
  return result;
}

function paramsFromJson(paramsJson) {
  const result = clonedeep(toJS(paramsJson));
  if (paramsJson.routes) {
    result.routes = paramsJson.routes.map((route) => {
      const resultRoute = clonedeep(toJS(route));
      if (route.arrivalTime) {
        resultRoute.arrivalTime = {};
        if (route.arrivalTime.min) {
          resultRoute.arrivalTime.min = moment(route.arrivalTime.min);
          resultRoute.arrivalTime.min = resultRoute.arrivalTime.min.startOf(
            'hour',
          );
        }
        if (route.arrivalTime.max) {
          const m = moment(route.arrivalTime.max);
          resultRoute.arrivalTime.max =
            m.minute() || m.second() || m.millisecond()
              ? m.add(1, 'hour').startOf('hour')
              : m.startOf('hour');
        }
      }
      if (route.departureTime) {
        resultRoute.departureTime = {};
        if (route.departureTime.min) {
          resultRoute.departureTime.min = moment(route.departureTime.min);
          resultRoute.departureTime.min = resultRoute.departureTime.min.startOf(
            'hour',
          );
        }
        if (route.departureTime.max) {
          const m = moment(route.departureTime.max);
          resultRoute.departureTime.max =
            m.minute() || m.second() || m.millisecond()
              ? m.add(1, 'hour').startOf('hour')
              : m.startOf('hour');
        }
      }
      if (route.connectedSegmentsDuration) {
        resultRoute.connectedSegmentsDuration = {};
        if (route.connectedSegmentsDuration.min !== undefined) {
          resultRoute.connectedSegmentsDuration.min = Math.floor(
            route.connectedSegmentsDuration.min / 60,
          );
        }
        if (route.connectedSegmentsDuration.max !== undefined) {
          resultRoute.connectedSegmentsDuration.max = Math.ceil(
            route.connectedSegmentsDuration.max / 60,
          );
        }
      }
      if (route.totalFlightDuration) {
        resultRoute.totalFlightDuration = {};
        if (route.totalFlightDuration.max) {
          resultRoute.totalFlightDuration.max = Math.ceil(
            route.totalFlightDuration.max / 60,
          );
        }
        if (route.totalFlightDuration.min) {
          resultRoute.totalFlightDuration.min = Math.floor(
            route.totalFlightDuration.min / 60,
          );
        }
      }
      return resultRoute;
    });
  }
  return result;
}

class FilterStore {
  initialParams;

  filterParams = DEFAULT_PARAMS;

  lastInitialParams;

  paramsConstraints = DEFAULT_CONSTRAINTS;

  applied = false;

  changeRouteParams = false;

  diffParams() {
    return paramsDiff(
      paramsToJson(this.paramsConstraints),
      paramsToJson(this.filterParams),
    );
  }

  get areAnyFiltersSelected() {
    return Object.keys(this.diffParams()).length !== 0;
  }

  get filterParamsString() {
    return jsonParamsToStr(this.diffParams());
  }

  get filterParamsJson() {
    return paramsToJson(this.filterParams);
  }

  setApplied(value) {
    this.applied = value;
  }

  setLastInitialParams(value) {
    this.lastInitialParams = { ...value };
  }

  setFilterParams(fp) {
    this.filterParams = { ...this.filterParams, ...fp };
  }

  setRouteParams(rp, idx) {
    this.filterParams.routes[idx] = { ...this.filterParams.routes[idx], ...rp };
    this.changeRouteParams = true;
  }

  parseParamsFromInfoString(infoString) {
    const idx = infoString.indexOf('f');
    if (idx !== -1) {
      const paramsString = infoString.slice(idx + 1);
      if (paramsString !== undefined) {
        const jsonParams = jsonParamsFromStr(paramsString);
        if (jsonParams !== undefined) {
          const parsedParams = paramsFromJson(jsonParams);
          this.setFilterParams(parsedParams);
        }
      }
    }
  }

  setInitialConstraintsAndValues(constraints, values) {
    const convertedValues = paramsFromJson(values);
    const convertedConstraints = paramsFromJson(constraints);
    this.sortOptions = convertedConstraints.orders;
    this.initialParams = { ...DEFAULT_PARAMS, ...convertedValues };
    this.lastInitialParams = { ...DEFAULT_PARAMS, ...convertedValues };
    this.paramsConstraints = convertedConstraints;
    this.setFilterParams({ ...DEFAULT_PARAMS, ...convertedValues });
  }

  resetFilterParams() {
    this.setFilterParams({ ...this.initialParams });
  }

  clearFilterParams() {
    this.filterParams = { ...DEFAULT_PARAMS };
  }

  get selectedAirports() {
    // массивы со всеми аэропортами для всех перелётов
    const airportsArrival = this.filterParams.routes.map(
      (r) => r.airports.arrival,
    );
    const airportsDeparture = this.filterParams.routes.map(
      (r) => r.airports.departure,
    );
    // массив с ограничениями
    const arrivalConstraints = this.paramsConstraints.routes.map(
      (r) => r.airports.arrival,
    );
    const departureConstraints = this.paramsConstraints.routes.map(
      (r) => r.airports.departure,
    );
    // сравнивается кол-во аэропортов у каждого перелёта для отправлений и прибытий
    const allArrival = airportsArrival.some(
      (a, i) => a.length !== arrivalConstraints[i].length,
    );
    const allDeparture = airportsDeparture.some(
      (a, i) => a.length !== departureConstraints[i].length,
    );
    if (!allArrival && !allDeparture) {
      return i18n.t('searchResults:anyAirport');
    }
    const airports = [];
    airportsArrival.map((a) => a.forEach((v) => airports.push(v.code)));
    airportsDeparture.map((a) => a.forEach((v) => airports.push(v.code)));
    return airports.join(', ');
  }

  afterFilter() {
    this.changeRouteParams = false;
  }
}

decorate(FilterStore, {
  filterParams: observable,
  lastInitialParams: observable,
  applied: observable,
  sortOptions: observable,
  setApplied: action,
  setFilterParams: action,
  setLastInitialParams: action,
  setRouteParams: action,
  clearFilterParams: action,
  areAnyFiltersSelected: computed,
  selectedAirports: computed,
  changeRouteParams: observable,
});

export default new FilterStore();
