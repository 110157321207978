import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import { nanoid } from 'nanoid';
import api from '../api';
import i18n from '../i18n';
import userStore from './userStore';
import { ReactComponent as PassportIcon } from '../assets/images/docs/passport-RF.svg';
import { ReactComponent as ForeignPassportIcon } from '../assets/images/docs/foreign-passport.svg';
import { ReactComponent as BirthNotificationIcon } from '../assets/images/docs/birth-notification.svg';
import { ReactComponent as DiplomaticPassportIcon } from '../assets/images/docs/diplomatic-passport.svg';
import { ReactComponent as InternationalPassportIcon } from '../assets/images/docs/international-passport.svg';
import { ReactComponent as MilitaryPassportIcon } from '../assets/images/docs/military-passport.svg';
import { ReactComponent as OfficerPassportIcon } from '../assets/images/docs/officer-passport.svg';
import { ReactComponent as ResidencePermitIcon } from '../assets/images/docs/residence-permit.svg';
import { ReactComponent as SeamanBookIcon } from '../assets/images/docs/seaman-book.svg';
import { ReactComponent as ServicePassportIcon } from '../assets/images/docs/service-passport.svg';
import { ID_CARD_TYPES } from '../constants';

const LOYALTY_CARD_TEMPLATE = {
  cardNumber: '',
  id: null,
  // для идентификации на фронте
  _id: null,
};

const RU_PASSPORT_VALID = [
  'Russian Federation',
  'Belarus',
  'Kazakhstan',
  'Armenia',
  'Kyrgyzstan',
];

const ID_ICONS = {
  PASSPORT: PassportIcon,
  FOREIGN_PASSPORT: ForeignPassportIcon,
  CIVIL_PASSPORT: InternationalPassportIcon,
  BIRTH_NOTIFICATION: BirthNotificationIcon,
  DIPLOMATIC_PASSPORT: DiplomaticPassportIcon,
  MILITARY_PASSPORT: MilitaryPassportIcon,
  OFFICER_PASSPORT: OfficerPassportIcon,
  RESIDENCE_PERMIT: ResidencePermitIcon,
  SEAMAN_BOOK: SeamanBookIcon,
  SERVICE_PASSPORT: ServicePassportIcon,
};

const EDITOR_STATE = {
  IDLE: 0,
  ADDING_DOCS: 1,
  ADDING_NEW_CONTACT: 2,
  EDITING_DOCS: 3,
};

/*
const categoryToString = (cat) => {
  if (cat === 'adult') {
    return i18n.t('passengerEditor:adult');
  }
  if (cat === 'child') {
    return i18n.t('passengerEditor:child');
  }
  if (cat === 'infant') {
    return i18n.t('passengerEditor:infant');
  }
  return '';
};
*/

class PassengerEditorStore {
  passenger;

  passengerFromCrm;

  state = EDITOR_STATE.IDLE;

  selectedDocsIdx = 0;

  documentOptions = [];

  contactTypes = [];

  prefilledWith = null;

  loyaltyCard = { ...LOYALTY_CARD_TEMPLATE };

  loyaltyCardsArray = [];

  wereCardsLoaded = false;

  cardTypes = [];

  initialize() {
    this.loadCountries();
  }

  loadLoyaltyCards() {
    if (!this.wereCardsLoaded) {
      api.Avia.getCustomerLoyaltyCards(userStore.token).then((cards) => {
        this.loyaltyCardsArray = cards.map((card) => ({
          ...card,
          _id: nanoid(),
        }));
        this.wereCardsLoaded = true;
      });
    }
  }

  addLoyaltyCard(card) {
    this.loyaltyCardsArray.push(card);
  }

  loadCountries() {
    api.Avia.getAllCountries().then(
      action((countries) => {
        this.countries = countries.sort((a, b) => {
          const nameA = a.nameFull.toLowerCase();
          const nameB = b.nameFull.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }),
    );
  }

  /* eslint-disable class-methods-use-this */
  validatePassenger(passenger, flight, category, isAnyPassengerOver18) {
    const REQUIRED_FIELDS = [
      'firstName',
      'lastName',
      'gender',
      'citizenship',
      'idCardType',
      'idCardNumber',
      'idCardExpireDate',
    ];
    const INVALID_CHARACTERS = /[А-яЁё]/;
    const INVALID_CHARACTERS_LATIN = /[A-z]/;
    const errors = {};
    let isValid = REQUIRED_FIELDS.map((field) => {
      const notEmpty = passenger[field] !== '';
      if (!notEmpty) {
        errors[field] = i18n.t('passengerEditor:emptyField');
      }
      return notEmpty;
    }).reduce((allValid, thisValid) => allValid && thisValid, true);
    // Ошибки
    // * Имя - Обязательно для заполнения латиницей

    const validateRules = [
      {
        field: 'firstName',
        error: 'emptyFirstName',
        emptyEnabled: false,
      },
      {
        field: 'lastName',
        error: 'emptyLastName',
        emptyEnabled: false,
      },
      {
        field: 'middleName',
        error: null,
        emptyEnabled: true,
      },
    ];

    validateRules.forEach((validateRule) => {
      if (
        !validateRule.emptyEnabled &&
        passenger[validateRule.field].trim() === ''
      ) {
        errors[validateRule.field] = i18n.t(
          `passengerEditor:${validateRule.error}`,
        );
        return;
      }

      if (
        (passenger.idCardType?.code === 'BIRTH_NOTIFICATION' ||
          passenger.idCardType?.code === 'PASSPORT') &&
        !flight.latinRegistration
      ) {
        if (
          passenger[validateRule.field].search(INVALID_CHARACTERS_LATIN) !== -1
        ) {
          errors[validateRule.field] = i18n.t(
            'passengerEditor:invalidCharactersLatin',
          );
        }
        return;
      }

      if (passenger[validateRule.field].search(INVALID_CHARACTERS) !== -1) {
        errors[validateRule.field] = i18n.t(
          'passengerEditor:invalidCharacters',
        );
      }
    });

    // * Дата рождения - Введенная дата не должна быть больше
    //   текущей и меньше, чем 10 день меньше текущей даты,
    //   а также возраст не может быть больше 150 (условие для бэка)
    if (passenger.birthday === '__.__.____' || passenger.birthday === '') {
      errors.birthday = i18n.t('passengerEditor:invalidDate');
    } else {
      const birthdayMoment = moment(passenger.birthday, 'DD.MM.YYYY', true);
      if (!isAnyPassengerOver18) {
        errors.birthday = i18n.t('passengerEditor:noAdultOver18');
      }
      if (!birthdayMoment.isValid()) {
        errors.birthday = i18n.t('passengerEditor:invalidDate');
      } else {
        const flightBeginMoment = moment(flight.segments[0].beginDate);
        if (
          birthdayMoment.isAfter(flightBeginMoment) ||
          // Должен пройти хотя бы 1 день после рождения пассажира
          moment().diff(birthdayMoment, 'day') < 1 ||
          flightBeginMoment.diff(birthdayMoment, 'year') > 150
        ) {
          errors.birthday = i18n.t('passengerEditor:invalidDate');
        } else {
          let categoryValid = true;
          // проверяем категорию на соответствие в каждом
          // сегменте перелёта
          flight.segments.forEach((s) => {
            const segmentBeginMoment = moment(s.beginDate);
            // возраст на момент старта сегмента
            const fullYearsAge = segmentBeginMoment.diff(
              birthdayMoment,
              'year',
              true,
            );
            // взрослые - 12+ лет
            if (fullYearsAge > 12) {
              if (category !== 'adult') {
                categoryValid = false;
              }
              // дети - от 2 до 12 лет
            } else if (fullYearsAge > 2) {
              if (category !== 'child') {
                categoryValid = false;
              }
              // младенцы - до 2-х лет
              // но можно купить младенцу билет категории "дети",
              // т.к. "младенец" - это без места, а клиент может хотеть
              // купить отдельное место младенцу
            } else if (category !== 'infant' && category !== 'child') {
              categoryValid = false;
            }
          });
          if (!categoryValid) {
            switch (category) {
              case 'adult':
                errors.birthday = i18n.t(
                  'passengerEditor:adultAgeConstraintsMsg',
                );
                break;
              case 'child':
                errors.birthday = i18n.t(
                  'passengerEditor:childAgeConstraintsMsg',
                );
                break;
              case 'infant':
                errors.birthday = i18n.t(
                  'passengerEditor:infantAgeConstraintsMsg',
                );
                break;
              default:
                break;
            }
          }
        }
      }
    }
    // * Дата окончания срока действия
    //   - Выбранная дата не может быть меньше или равна дате вылета первого сегмента.
    if (passenger.idCardType !== '') {
      if (
        passenger.idCardExpireDate === '__.__.____' ||
        passenger.idCardExpireDate === ''
      ) {
        errors.idCardExpireDate = i18n.t('passengerEditor:invalidDate');
      } else {
        const idCardExpireDateMoment = moment(
          passenger.idCardExpireDate,
          'DD.MM.YYYY',
          true,
        );
        if (passenger.idCardType.code === 'BIRTH_NOTIFICATION') {
          idCardExpireDateMoment.subtract(1, 'day');
        }
        if (!idCardExpireDateMoment.isValid()) {
          errors.idCardExpireDate = i18n.t('passengerEditor:invalidDate');
        } else {
          // у перелетов указано точное время, делаем его одинаковым с idCardExpireDateMoment,
          // чтобы не учитывать
          const firstFlightStartMoment = moment(flight.segments[0].beginDate)
            .set('hour', 0)
            .set('minute', 0);
          const lastFlightEndMoment = moment(
            flight.segments[flight.segments.length - 1].endDate,
          )
            .set('hour', 0)
            .set('minute', 0);
          if (idCardExpireDateMoment.isBefore(firstFlightStartMoment)) {
            errors.idCardExpireDate = i18n.t('passengerEditor:invalidDate');
          } else if (idCardExpireDateMoment.isBefore(lastFlightEndMoment)) {
            if (passenger.idCardType.code === 'BIRTH_NOTIFICATION') {
              // ошибка в поле типа документа, тк у свидетельства
              // нет поля срока действия
              errors.idCardType = i18n.t('passengerEditor:invalidExpireDate');
            } else {
              errors.idCardExpireDate = i18n.t(
                'passengerEditor:invalidExpireDate',
              );
            }
          }
        }
      }
    }
    // если перелёт не внутри России или некоторых стран СНГ, то нельзя выбирать
    // свидетельство о рождении
    let validRuPassport = true;
    flight.segments.forEach((segment) => {
      validRuPassport =
        validRuPassport &&
        RU_PASSPORT_VALID.includes(segment.beginLocation.countryNameEng) &&
        RU_PASSPORT_VALID.includes(segment.endLocation.countryNameEng);
    });
    if (
      passenger.idCardType !== null &&
      passenger.idCardType !== undefined &&
      (passenger.idCardType.code === 'BIRTH_NOTIFICATION' ||
        passenger.idCardType.code === 'PASSPORT') &&
      !validRuPassport
    ) {
      errors.idCardType = i18n.t('passengerEditor:invalidIdCardType');
    }
    // Валидация номера документа
    if (passenger.idCardType !== undefined && passenger.idCardType !== null) {
      const docCode = passenger.idCardType.code;
      // Паспорт РФ
      if (docCode === ID_CARD_TYPES.PASSPORT) {
        if (passenger.idCardNumber.length !== 10) {
          errors.idCardNumber = i18n.t('passengerEditor:invalidPassportNumber');
        }
      }
      // Загранпаспорт РФ
      if (docCode === ID_CARD_TYPES.FOREIGN_PASSPORT) {
        if (passenger.idCardNumber.length !== 9) {
          errors.idCardNumber = i18n.t(
            'passengerEditor:invalidForeignPassportNumber',
          );
        }
      }

      if (docCode === ID_CARD_TYPES.BIRTH_NOTIFICATION) {
        if (
          passenger.idCardNumber.match(
            /^[lLxXvViIcCdDmM]{1,4}[А-Яа-я]{1,3}[0-9]{6}$/,
          ) === null
        ) {
          errors.idCardNumber =
            'Неверный формат номера документа, номер должен состоять из римских цифр, кирилических букв и цифр без пробелов';
        }
      }
    } else {
      errors.idCardType = 'Поле не может быть пустым';
    }
    // Остальные документы не валидируем
    isValid = isValid && Object.keys(errors).length === 0;
    return {
      isValid,
      errors,
    };
  }

  editPassenger(passenger, fromCrm = false) {
    this.prefilledWith = null;
    this.passenger = { ...passenger };
    this.passengerFromCrm = fromCrm;
  }

  setState(val) {
    this.state = val;
  }

  setLoyaltyCard(val, field) {
    if (field === 'type') {
      this.loyaltyCard[field] = { ...val };
    } else if (field === undefined) {
      this.loyaltyCard = { ...val };
    } else {
      this.loyaltyCard[field] = val;
    }
  }

  resetLoyaltyCard() {
    this.loyaltyCard = { ...LOYALTY_CARD_TEMPLATE };
  }

  setSelectedDocsIdx(val) {
    this.selectedDocsIdx = val;
  }

  savePassenger(data) {
    const { token } = userStore;
    return new Promise((resolve, reject) => {
      const requestData = {
        birthDate: moment(data.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        citizenship: data.citizenship.nameShort,
        crmId: data.crmInfo ? data.crmInfo.id : null,
        documentId: data.documentId ? data.documentId : null,
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        gender: data.gender.code,
        idCardExpireDate: moment(data.idCardExpireDate, 'DD.MM.YYYY').format(
          'YYYY-MM-DD',
        ),
        idCardNumber: data.idCardNumber,
        idCardType: data.idCardType.crmId,
        uid: data.uid ? data.uid : null,
        // если не сам клиент, указываем тип контакта
        contactType: data.customer ? null : data.contactType,
      };
      api.Avia.savePassenger(requestData, token).then(
        action((response) => resolve(response)),
        action(() => reject()),
      );
    });
  }

  getDocumentOptions() {
    if (this.documentOptions.length === 0) {
      api.Avia.getIdDocuments().then(
        action((options) => {
          // eslint-disable-next-line
          this.documentOptions.push.apply(this.documentOptions, options);
        }),
      );
    }
  }

  getContactTypes() {
    if (this.contactTypes.length === 0) {
      api.Avia.getContactTypes(userStore.token)
        .then(
          action((types) => {
            this.contactTypes = types;
          }),
        )
        .catch(() => {});
    }
  }

  saveLoyaltyCard(data) {
    const { token } = userStore;
    return new Promise((resolve, reject) => {
      const requestData = {
        cardNumber: data.cardNumber,
        description: data.description ? data.description : null,
        expiryDate: data.expiryDate ? data.expiryDate : null,
        id: data.id ? data.id : null,
        issueDate: data.issueDate ? data.issueDate : null,
        password: data.password ? data.password : null,
        type: {
          color: data.type.color,
          id: data.type.id,
          logoUrl: data.type.logoUrl,
          name: data.type.name,
          position: data.type.position,
        },
      };
      api.Avia.saveLoyaltyCard(requestData, token).then(
        action((response) => resolve(response)),
        action(() => reject()),
      );
    });
  }

  deleteLoyaltyCard(id) {
    const { token } = userStore;
    return new Promise((resolve, reject) => {
      api.Avia.deleteLoyaltyCard(id, token).then(
        action((response) => resolve(response)),
        action(() => reject()),
      );
    });
  }

  deleteCardFromArray(id) {
    this.loyaltyCardsArray = this.loyaltyCardsArray.filter(
      (card) => card._id !== id,
    );
  }

  editLoyaltyCard(card) {
    const index = this.loyaltyCardsArray.findIndex((c) => c.id === card.id);
    this.loyaltyCardsArray[index] = { ...card };
  }

  getLoyaltyCardTypes() {
    if (this.cardTypes.length === 0) {
      api.Avia.getLoyaltyCardTypes(userStore.token).then(
        action((types) => {
          this.cardTypes = types;
        }),
      );
    }
  }

  isPrefilledWith(docId) {
    return this.prefilledWith === docId;
  }

  markAsPrefilledWith(docId) {
    this.prefilledWith = docId;
  }
}

decorate(PassengerEditorStore, {
  countries: observable,
  passenger: observable.deep,
  state: observable,
  documentOptions: observable,
  selectedDocsIdx: observable,
  loyaltyCard: observable,
  wereCardsLoaded: observable,
  cardTypes: observable,
  contactTypes: observable,
  getContactTypes: action,
  getDocumentOptions: action,
  loadCountries: action,
  loadLoyaltyCards: action,
  addLoyaltyCard: action,
  resetLoyaltyCard: action,
  editPassenger: action,
  setState: action,
  setLoyaltyCard: action,
  setSelectedDocsIdx: action,
  saveLoyaltyCard: action,
  deleteLoyaltyCard: action,
  deleteCardById: action,
  editLoyaltyCard: action,
  getLoyaltyCardTypes: action,
});

const passengerEditorStore = new PassengerEditorStore();

passengerEditorStore.initialize();

export default passengerEditorStore;
export { EDITOR_STATE, ID_ICONS, RU_PASSPORT_VALID };
