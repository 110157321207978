import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { useStores } from '../../../hooks/useStores';

const ComplexRouteSegmentContainer = styled.div`
  position: relative;
  padding: 0 5px;
  height: 50px;
  box-sizing: border-box;
`;
const LocationSelectorBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 9px 10px;
  vertical-align: top;
  width: 105px;
`;
const DateSelectorBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  padding: 9px 10px;
`;
const VerticalSeparatorLine = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 5px 0;
  height: 40px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.borderColor};
`;
const MainText = styled.div`
  font-size: 16px;
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColor};
`;
const SubtitleText = styled.div`
  color: ${(props) => props.theme.colors.textColorLight};
  font-weight: 600;
  font-size: 10px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PlaceholderText = styled.div`
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColorLight};
  font-size: 16px;
  line-height: 31px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  margin: auto;
  width: 32px;
  height: 32px;
  border-radius: 40px;
  background-color: #eeebe5;
  text-align: center;
  line-height: 37px;
`;
const HorizontalSeparatorLineSmall = styled.div`
  height: 1px;
  width: 315px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.borderColor};
`;
const HorizontalSeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.borderColor};
`;
const AddFlightButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: ${(props) => props.theme.colors.textColorLighter};
  font-size: 16px;
  padding-left: 120px;
  line-height: 50px;
`;
const AddFlightIcon = styled.div`
  line-height: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 94px;
  width: 16px;
  height: 16px;
`;
const NoMoreFlights = styled.div`
  position: relative;
  width: 345px;
  height: 50px;
  color: ${(props) => props.theme.colors.textColorLighter};
  font-size: 16px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
`;

const ComplexRouteSegment = (props) => {
  const { t } = useTranslation(['search']);
  const { route, error, deletable, onDeletePress } = props;
  const { searchStore } = useStores();
  const history = useHistory();
  useEffect(() => {
    if (
      route.to &&
      route.from &&
      !route.dates &&
      searchStore.shouldAutoOpenCalendar
    ) {
      history.push('/search/dates');
      searchStore.setEditedRoute(route);
      searchStore.setShouldAutoOpenCalendar(false);
    }
  }, [route, history]);
  return (
    <ComplexRouteSegmentContainer>
      <Link
        to="/search/location"
        onClick={() => searchStore.editLocationOnRoute('from', route)}
      >
        <LocationSelectorBlock>
          {route.from !== undefined && (
            <div>
              <MainText>{route.from.airportCode}</MainText>
              <SubtitleText>{route.from.cityName}</SubtitleText>
            </div>
          )}
          {route.from === undefined && (
            <PlaceholderText error={error ? error.from : undefined}>
              {t('from')}
            </PlaceholderText>
          )}
        </LocationSelectorBlock>
      </Link>
      <VerticalSeparatorLine />
      <Link
        to="/search/location"
        onClick={() => searchStore.editLocationOnRoute('to', route)}
      >
        <LocationSelectorBlock>
          {route.to !== undefined && (
            <div>
              <MainText>{route.to.airportCode}</MainText>
              <SubtitleText>{route.to.cityName}</SubtitleText>
            </div>
          )}
          {route.to === undefined && (
            <PlaceholderText error={error ? error.to : undefined}>
              {t('to')}
            </PlaceholderText>
          )}
        </LocationSelectorBlock>
      </Link>
      <VerticalSeparatorLine />
      <Link
        to="/search/dates"
        onClick={() => searchStore.setEditedRoute(route)}
      >
        <DateSelectorBlock>
          {route.dates !== undefined && route.dates.startDate !== undefined && (
            <div>
              <MainText error={error && error.startDate !== undefined}>
                {route.dates.startDate.format('D MMM').split('.')[0]}
              </MainText>
              <SubtitleText>{route.dates.startDate.format('dd')}</SubtitleText>
            </div>
          )}
          {(route.dates === undefined ||
            route.dates.startDate === undefined) && (
            <PlaceholderText error={error ? error.startDate : undefined}>
              {t('when')}
            </PlaceholderText>
          )}
        </DateSelectorBlock>
      </Link>
      {deletable && (
        <CloseButton onClick={() => onDeletePress()}>
          <CloseIcon />
        </CloseButton>
      )}
    </ComplexRouteSegmentContainer>
  );
};

const AddFlight = (props) => {
  const { t } = useTranslation(['search']);
  const theme = useTheme();
  const { canAddMore, onAddFlight } = props;
  if (canAddMore) {
    return (
      <AddFlightButton onClick={onAddFlight}>
        <AddFlightIcon>
          <PlusIcon fill={theme.colors.textColorLight} />
        </AddFlightIcon>
        {t('addFlight')}
      </AddFlightButton>
    );
  }
  return <NoMoreFlights>{t('noMoreFlights')}</NoMoreFlights>;
};

const ComplexRouteSelector = (props) => {
  const { routes, errors } = props;
  const { searchStore } = useStores();
  /* eslint-disable react/no-array-index-key */
  return (
    <div>
      {routes.map((route, index) => (
        <div key={index}>
          <ComplexRouteSegment
            error={errors ? errors[index] : undefined}
            route={route}
            deletable={index > 0}
            onDeletePress={() =>
              props.onChange([
                ...routes.slice(0, index),
                ...routes.slice(index + 1),
              ])
            }
          />
          <HorizontalSeparatorLineSmall />
        </div>
      ))}
      <AddFlight
        canAddMore={routes.length < 6}
        onAddFlight={() => {
          props.onChange([...routes.slice(), {}]);
          searchStore.autocompleteLocations(routes[routes.length - 1]);
        }}
      />
      <HorizontalSeparatorLine />
    </div>
  );
};

export default ComplexRouteSelector;
