import React from 'react';
import { Global, css, useTheme } from '@emotion/react';

const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        html,
        body {
          width: 100%;
          height: 100%;
        }

        body {
          margin: 0;
          padding: 0;
          font-family: 'SF Pro Display', 'sans-serif';
          color: ${theme.colors.textColor};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        ::placeholder {
          color: ${theme.colors.textColorLight};
        }

        #root {
          width: 100%;
          height: 100%;
        }

        a {
          color: inherit;
          text-decoration: none;
        }

        .App {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
        }

        @keyframes onAutoFillStart {
        }

        @keyframes onAutoFillCancel {
        }

        input:-webkit-autofill {
          animation-name: onAutoFillStart;
          transition: background-color 50000s ease-in-out 0s;
        }

        input:not(:-webkit-autofill) {
          animation-name: onAutoFillCancel;
        }
      `}
    />
  );
};

export default GlobalStyles;
