import { decorate, observable, action } from 'mobx';
import { parseQueryVars } from '../util';

class CommonStore {
  token;

  openFlightId;

  wasRedirectedFromPayment = false;

  redirectBookingId;

  initialQueryVars;

  searchPrefilled = false;

  showFormErrors = false;

  wasRedirectedFromSuccessAuth = false;

  constructor() {
    this.parseQueryVars();
  }

  parseQueryVars() {
    this.initialQueryVars = parseQueryVars();
  }

  setSearchPrefilled(value) {
    this.searchPrefilled = value;
  }

  setShowFormErrors(value) {
    this.showFormErrors = value;
  }

  setRedirectedFromPayment(value) {
    this.wasRedirectedFromPayment = value;
  }

  setRedirectBookingId(id) {
    this.redirectBookingId = id;
  }

  setOpenFlightId(id) {
    this.openFlightId = id;
  }

  setToken(token) {
    this.token = token;
  }

  setWasRedirectedFromSuccessAuth(value) {
    this.wasRedirectedFromSuccessAuth = value;
  }
}

decorate(CommonStore, {
  openFlightId: observable,
  token: observable,
  wasRedirectedFromPayment: observable,
  redirectBookingId: observable,
  initialQueryVars: observable,
  showFormErrors: observable,
  searchPrefilled: observable,
  wasRedirectedFromSuccessAuth: observable,
  setSearchPrefilled: action,
  parseQueryVars: action,
  setOpenFlightId: action,
  setToken: action,
  setShowFormErrors: action,
  setWasRedirectedFromSuccessAuth: action,
});

export default new CommonStore();
