import { decorate, action } from 'mobx';
import { PARTNER_ID } from '../constants';
import commonStore from './commonStore';
import userStore from './userStore';
import api from '../api';

/* eslint-disable class-methods-use-this */
class ReservationStore {
  lastBookingId;

  checkPricing(flightId) {
    return new Promise((resolve, reject) => {
      const { currentUser, token } = userStore;
      const requestData = {
        systemId: 'prime.mobile',
        partnerId: PARTNER_ID,
        userId: currentUser.username,
        userToken: token,
        obj: flightId,
      };
      // Если пользователь приложения - ассистент
      if (currentUser.profileType === 'ASSISTANT') {
        requestData.customerId = commonStore.initialQueryVars.customerId;
        // Если пользователь - клиент
      } else {
        requestData.customerId = currentUser.username;
      }
      api.Avia.checkPricing(requestData).then(
        action((response) => {
          this.lastBookingId = response.bookingId;
          resolve(response);
        }),
        action((res) => {
          reject(res.response.body.code);
        }),
      );
    });
  }

  createReservation(contactInfo, passengers) {
    return new Promise((resolve, reject) => {
      const { currentUser, token } = userStore;
      if (currentUser.profileType !== 'ASSISTANT') {
        // Если пользователь приложения - клиент (НЕ ассистент)
        const requestData = {
          systemId: 'prime.mobile',
          partnerId: PARTNER_ID,
          userId: currentUser.username,
          customerId: currentUser.username,
          userToken: token,
          obj: {
            bookingId: this.lastBookingId,
            customer: {
              email: contactInfo.email,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              middleName: currentUser.middleName,
              phone: contactInfo.phone.replaceAll('+', ''),
            },
            passengers,
          },
        };
        api.Avia.createReservation(requestData).then(
          action((response) => resolve(response)),
          action(() => reject()),
        );
      } else {
        // Если пользователь приложения - ассистент
        api.Prime.getCustomerData(
          commonStore.initialQueryVars.customerId,
          userStore.token,
        ).then((customer) => {
          const requestData = {
            systemId: 'prime.mobile',
            partnerId: PARTNER_ID,
            userId: currentUser.username,
            customerId: customer.username,
            userToken: token,
            obj: {
              bookingId: this.lastBookingId,
              customer: {
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                middleName: customer.middleName,
                phone: customer.phone.replaceAll('+', ''),
              },
              passengers,
            },
          };
          api.Avia.createReservation(requestData).then(
            action((response) => resolve(response)),
            action(() => reject()),
          );
        });
      }
    });
  }

  preparePayment(paymentMethod) {
    return new Promise((resolve, reject) => {
      const { currentUser, token } = userStore;
      const requestData = {
        systemId: 'prime.mobile',
        userId: currentUser.username,
        userToken: token,
        obj: {
          bookingId: this.lastBookingId,
          paymentType: paymentMethod,
        },
      };
      // Если пользователь приложения - ассистент
      if (currentUser.profileType === 'ASSISTANT') {
        requestData.customerId = commonStore.initialQueryVars.customerId;
        // Если пользователь - клиент
      } else {
        requestData.customerId = currentUser.username;
      }
      api.Avia.preparePayment(requestData).then(
        action((response) => resolve(JSON.parse(response.content))),
        action(() => reject()),
      );
    });
  }

  createBookingRequest(flightId) {
    return new Promise((resolve, reject) => {
      const { token } = userStore;
      const requestData = {
        userToken: token,
        flightId: flightId * 1,
      };
      api.Avia.createBookingRequest(requestData).then(
        (response) => resolve(response),
        () => reject(),
      );
    });
  }
}

decorate(ReservationStore, {});

export default new ReservationStore();
