import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ROUTE } from './constants';
import { useStores } from './hooks/useStores';
import {
  LOADING_MODAL_VARIANTS,
  LoadingModal,
} from './components/common/LoadingModal';
import Search from './components/routes/search';
import LocationSearch from './components/routes/search/location';
import DateSelection from './components/routes/search/dates';

const PassengerSelection = lazy(() =>
  import('./components/routes/search/passengers'),
);
const SearchResults = lazy(() => import('./components/routes/search/results'));
const Details = lazy(() => import('./components/routes/flight'));
const Remarks = lazy(() => import('./components/routes/flight/remarks'));
const Rules = lazy(() => import('./components/routes/flight/remarks/rules'));
const WebsiteRules = lazy(() => import('./components/routes/website-rules'));
const PassengerEditor = lazy(() =>
  import('./components/routes/flight/passenger-editor'),
);
const CitizenShipSelection = lazy(() =>
  import('./components/routes/flight/passenger-editor/citizenship'),
);
const PassengersList = lazy(() =>
  import('./components/routes/flight/passenger-adder'),
);
const LoyaltyCards = lazy(() =>
  import('./components/routes/flight/loyalty-card'),
);
const CardEditing = lazy(() =>
  import('./components/routes/flight/loyalty-card/card-editing'),
);
const CardTypes = lazy(() =>
  import('./components/routes/flight/loyalty-card/card-types'),
);
const BookingDetails = lazy(() =>
  import('./components/routes/booking-details'),
);
const ResultsFilter = lazy(() =>
  import('./components/routes/search/results/filter'),
);
const FlightFilter = lazy(() =>
  import('./components/routes/search/results/filter/flight'),
);
const AirportsFilter = lazy(() =>
  import('./components/routes/search/results/filter/airports'),
);
const VendorsFilter = lazy(() =>
  import('./components/routes/search/results/filter/flight/vendors'),
);
const Auth = lazy(() => import('./components/routes/auth'));

const AviaRouter = () => {
  const { t } = useTranslation('common');
  const { commonStore } = useStores();
  const { initialQueryVars } = commonStore;
  const paymentRedirect = initialQueryVars.payment_redirect;
  const bookingId = initialQueryVars.booking_id;
  return (
    <Router>
      <Suspense
        fallback={
          <LoadingModal
            show
            text={t('loading')}
            variant={LOADING_MODAL_VARIANTS.CHECKING_PRICE}
          />
        }
      >
        <Switch>
          <Route exact path="/search" component={Search} />
          <Route exact path="/search/location" component={LocationSearch} />
          <Route exact path="/search/dates" component={DateSelection} />
          <Route
            exact
            path="/search/passengers"
            component={PassengerSelection}
          />
          <Route
            exact
            path={['/search/results', '/search/:infoStr']}
            component={SearchResults}
          />
          <Route
            exact
            path="/search/:infoStr/filter"
            component={ResultsFilter}
          />
          <Route
            exact
            path="/search/:infoStr/filter/flight/:flightIdx"
            component={FlightFilter}
          />
          <Route
            exact
            path="/search/:infoStr/filter/airports"
            component={AirportsFilter}
          />
          <Route
            exact
            path="/search/:infoStr/filter/flight/:flightIdx/vendors"
            component={VendorsFilter}
          />
          <Route exact path="/flight/:flightId" component={Details} />
          <Route
            exact
            path="/flight/:flightId/:segmentId/remarks"
            component={Remarks}
          />
          <Route
            exact
            path="/flight/:flightId/:segmentId/remarks/rules"
            component={Rules}
          />
          <Route exact path="/flight/:flightId/rules" component={Rules} />
          <Route exact path="/terms-of-service" component={WebsiteRules} />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex"
            component={PassengerEditor}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/new-document"
            component={PassengerEditor}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/citizenship"
            component={CitizenShipSelection}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/editor"
            component={PassengersList}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/loyalty-card"
            component={LoyaltyCards}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/edit-loyalty-card"
            component={CardEditing}
          />
          <Route
            exact
            path="/flight/:flightId/passenger/:passengerIndex/card-types"
            component={CardTypes}
          />
          <Route
            exact
            path="/booking/:bookingId/details"
            component={BookingDetails}
          />
          <Route exact path="/auth" component={Auth} />
          <Redirect
            exact
            from="/"
            to={
              paymentRedirect !== undefined
                ? `/booking/${bookingId}/details`
                : DEFAULT_ROUTE
            }
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AviaRouter;
