import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TicketsIcon from '../../../common/icons/TicketsIcon';

const ResultContainer = styled.div`
  cursor: pointer;
  padding: 0 15px;
  margin-bottom: 15px;
`;
const CityName = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const AirportLine = styled.div`
  position: relative;
`;
const AirportName = styled.div`
  color: ${(props) => props.theme.colors.textColorLighter};
  padding-right: 40px;
  font-size: 16px;
`;
const AirportCode = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: ${(props) => props.theme.colors.primaryColor};
`;
const SectionContainer = styled.div`
  margin-bottom: 25px;
`;

const SectionLabel = styled.div`
  padding: 0 15px 10px;
  font-size: 14px;
  color: #a7abac;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 197px;
  left: 0;
  right: 0;
  text-align: center;
`;

const ErrorText = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.textColorLighter};
`;

const Result = ({ item, onResultClick }) => (
  <Link to="/search" onClick={() => onResultClick(item)}>
    <ResultContainer>
      <CityName>{item.cityName}</CityName>
      <AirportLine>
        <AirportName>
          {item.airportName},{item.countryName}
        </AirportName>
        <AirportCode>{item.airportCode}</AirportCode>
      </AirportLine>
    </ResultContainer>
  </Link>
);

/* eslint-disable react/no-array-index-key */
const ResultsSection = ({ sectionName, items, onResultClick }) => (
  <SectionContainer>
    <SectionLabel>{sectionName}</SectionLabel>
    <div>
      {items.map((item, index) => (
        <Result onResultClick={onResultClick} key={index} item={item} />
      ))}
    </div>
  </SectionContainer>
);

const SearchResultsList = (props) => {
  const { t } = useTranslation('search');
  const {
    results,
    initialSuggestions,
    showInitialSuggestions,
    onResultClick,
  } = props;
  return (
    <div>
      {!showInitialSuggestions &&
        results !== undefined &&
        results !== null &&
        results.length > 0 && (
          <ResultsSection
            onResultClick={onResultClick}
            sectionName={t('searchResults')}
            items={results}
          />
        )}
      {showInitialSuggestions &&
        initialSuggestions !== undefined &&
        initialSuggestions.length !== 0 && (
          <ResultsSection
            onResultClick={onResultClick}
            sectionName={t('popularDestinations')}
            items={initialSuggestions}
          />
        )}
      {!showInitialSuggestions &&
        results !== undefined &&
        results.length === 0 && (
          <ErrorContainer>
            <TicketsIcon />
            <ErrorText>{t('noResults')}</ErrorText>
          </ErrorContainer>
        )}
    </div>
  );
};

export default SearchResultsList;
