import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import { nanoid } from 'nanoid';
import i18n from '../i18n';
import { filterUndefinedEmptyStrings } from '../util';
import searchStore from './searchStore';
import passengerEditorStore from './passengerEditorStore';
import { DEV } from '../constants';
import api from '../api';
import userStore from './userStore';

const RUSSIA_TEMPLATE = {
  capitalCity: 'Москва',
  code: 'RU',
  code3: 'RUS',
  codeInt: '643',
  isActive: true,
  nameEng: 'Russian Federation',
  nameFull: 'Российская Федерация',
  nameShort: 'Россия',
  territory: 'ЕВРОПА',
};

const EMPTY_PASSENGER_DOC_TEMPLATE = {
  id: null,
  idCardExpire: '',
  idCardNumber: '',
  idCardType: '',
};

const PASSENGER_TEMPLATE = {
  lastName: '',
  firstName: '',
  middleName: '',
  citizenship: '',
  idCardType: '',
  idCardNumber: '',
  idCardExpireDate: '',
  category: '',
  gender: '',
  birthday: '',
  contactType: '',
  discountCard: '',
  customer: false,
};

const newId = () => nanoid(5);

// сначала проверяем, указано ли фио просто в объекте пассажира,
// если нет, то смотрим, указано ли в срм инфо
const getPassengerName = (passenger, field) => {
  if (passenger[field] !== null) {
    return passenger[field];
  }
  if (passenger.crmInfo[field] !== null) {
    return passenger.crmInfo[field];
  }
  return '';
};

function getPassengerCategory(passenger) {
  const calcCatFromBirthday = (birthdayMoment) => {
    const nowMoment = moment();
    const fullYearsAge = nowMoment.diff(birthdayMoment, 'year');
    if (fullYearsAge > 12) {
      return 'adult';
    }
    if (fullYearsAge > 2) {
      return 'child';
    }
    return 'infant';
  };
  if (passenger === undefined) {
    return 'unknown';
  }
  let birthdayMoment = moment(passenger.birthday, 'DD.MM.YYYY', true);
  if (!birthdayMoment.isValid()) {
    birthdayMoment = moment(passenger.birthdayDate, 'YYYY-MM-DD', true);
    if (!birthdayMoment.isValid()) {
      return 'unknown';
    }
    return calcCatFromBirthday(birthdayMoment);
  }
  return calcCatFromBirthday(birthdayMoment);
}

function passengersFromCrmJson(passengers) {
  return passengers.map((p) => {
    const { citizenship: passengerCitizenship } = p;
    let citizenship = '';
    const citizenshipMatch = passengerEditorStore.countries.find(
      (c) => c.code === passengerCitizenship,
    );
    if (citizenshipMatch !== undefined) {
      citizenship = citizenshipMatch;
    }
    const formattedDocs = p.documents.map((d) => ({
      ...d,
      idCardExpire: moment(d.idCardExpire, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    }));

    return {
      crmInfo: p.crmInfo ? p.crmInfo : null,
      lastName: getPassengerName(p, 'lastName'),
      firstName: getPassengerName(p, 'firstName'),
      middleName: getPassengerName(p, 'middleName'),
      citizenship,
      documentId: null,
      idCardType: p.idCardType !== null ? { code: p.idCardType } : '',
      idCardNumber: p.idCardNumber !== null ? p.idCardNumber : '',
      documents: formattedDocs,
      gender: p.gender !== null ? { code: p.gender } : '',
      birthday:
        p.birthDate !== null
          ? moment(p.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
          : '',
      idCardExpireDate:
        p.idCardExpireDate !== null
          ? moment(p.idCardExpireDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
          : '',
      _id: p._id !== null ? p.crmInfo.id : newId(),
      uid: p.uid ? p.uid : null,
      loyaltyCard: null,
      customer: p.customer,
      contactType: p.crmInfo.contactType,
    };
  });
}

class PassengersStore {
  selectedCategories = {
    adult: 0,
    child: 0,
    infant: 0,
  };

  passengers = [];

  addedPassengers = [];

  crmPassengers = [];

  countries = [];

  _crmPassengersLoaded = false;

  arePassengersLoading = false;

  shouldPassengersLoad = true;

  constructor() {
    if (DEV.STORE_PASSENGERS_IN_LOCAL_STORAGE) {
      this.passengers = JSON.parse(localStorage.getItem('DEV_PASSENGERS'));
      if (this.passengers === null) {
        this.passengers = [];
      }
    }
  }

  getPassengerCategoryByPassengerIndex(index) {
    if (this.passengers.length >= index + 1) {
      return getPassengerCategory(this.passengers[index]);
    }
    return 'unknown';
  }

  get passengersJson() {
    return this.passengers.map((pass) =>
      filterUndefinedEmptyStrings({
        lastName: pass.lastName,
        firstName: pass.firstName,
        middleName: pass.middleName,
        isCustomer: pass.customer,
        citizenship: pass.citizenship.code,
        idCardType: pass.idCardType.code,
        idCardNumber: pass.idCardNumber,
        idCardExpireDate:
          pass.idCardExpireDate !== ''
            ? moment(pass.idCardExpireDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
            : moment()
                .add('10', 'year')
                .format('YYYY-MM-DD'),
        category: pass.$category.toUpperCase(),
        gender: pass.gender.code,
        birthday: moment(pass.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        discountCardNumber:
          pass.loyaltyCard && pass.loyaltyCard.cardNumber
            ? pass.loyaltyCard.cardNumber
            : '',
        discountCardId:
          pass.loyaltyCard && pass.loyaltyCard.id ? pass.loyaltyCard.id : '',
      }),
    );
  }

  arePassengersValid(foreignSegmentsIncluded) {
    // 1. Количество и категории пользователей
    const { actualCategories, selectedCategories } = this;
    const categoriesDiff = {
      adult: actualCategories.adult - selectedCategories.adult,
      child: actualCategories.child - selectedCategories.child,
      infant: actualCategories.infant - selectedCategories.infant,
    };
    if (
      categoriesDiff.adult !== 0 ||
      categoriesDiff.child !== 0 ||
      categoriesDiff.infant !== 0
    ) {
      return i18n.t('passengerEditorEmbedded:invalidCategories');
    }
    // 2. Нельзя свидетельство о рождении,
    // если есть заграничные трансферы или назначения
    const docsValid = this.passengers.reduce((allValid, passenger) => {
      if (passenger) {
        const citizenship = passenger.citizenship
          ? passenger.citizenship.code
          : passenger.citizenship;
        if (citizenship === 'RU') {
          const idCardType = passenger.idCardType
            ? passenger.idCardType.code
            : passenger.idCardType;
          if (foreignSegmentsIncluded) {
            return idCardType !== 'BIRTH_NOTIFICATION';
          }
          return allValid && true;
        }
        return allValid && true;
      }
      return false;
    }, true);
    if (!docsValid) {
      return i18n.t('passengerEditorEmbedded:invalidDocs');
    }
    return true;
  }

  get actualCategories() {
    return this.passengers
      .map((p) => p && p.$category)
      .reduce(
        (categories, category) => {
          if (category === undefined) {
            // eslint-disable-next-line
            categories.unknown += 1;
          } else {
            // eslint-disable-next-line
            categories[category] += 1;
          }
          return categories;
        },
        { adult: 0, child: 0, infant: 0, unknown: 0 },
      );
  }

  get categoriesAllowed() {
    return Object.fromEntries(
      Object.entries(this.actualCategories).map(
        ([categoryName, categoryValue]) => [
          categoryName,
          this.selectedCategories[categoryName] - categoryValue,
        ],
      ),
    );
  }

  loadPassengersFromCRM() {
    if (!this.shouldPassengersLoad) {
      return;
    }
    this.shouldPassengersLoad = false;
    this.arePassengersLoading = true;
    api.Avia.getPassengersFromCRM(userStore.token).then(
      action((response) => {
        this._crmPassengersLoaded = true;
        // порядок с бэка: сначала пользователь, потом уже летавшие пассажиры с uid, затем оставшиеся
        this.crmPassengers = passengersFromCrmJson(response);
        this.arePassengersLoading = false;
      }),
    );
  }

  addEmptyPassenger() {
    const p = { ...PASSENGER_TEMPLATE, _id: newId() };
    this.addedPassengers.push(p);
    return [p, this.addedPassengers.length - 1];
  }

  setCrmPassenger(passenger, passengerIdx) {
    this.crmPassengers[passengerIdx] = passenger;
  }

  setContactsData(passenger, passengerIdx) {
    this.contactsData[passengerIdx] = passenger;
  }

  setShouldPassengersLoad(val) {
    this.shouldPassengersLoad = val;
  }

  setCustomerDocs(passenger, docsIdx) {
    // если это новые документы, добавляем их. если нет, то изменяем редактируемые
    if (this.customerData.documents.length <= docsIdx) {
      this.customerData.documents.push({
        idCardExpire: passenger.idCardExpireDate,
        idCardNumber: passenger.idCardNumber,
        idCardType: passenger.idCardType,
      });
    } else {
      this.customerData.documents[docsIdx].idCardExpire =
        passenger.idCardExpire;
      this.customerData.documents[docsIdx].idCardNumber =
        passenger.idCardNumber;
      this.customerData.documents[docsIdx].idCardType = passenger.idCardType;
    }
  }

  setAddedPassenger(passenger, passengerIdx) {
    this.addedPassengers[passengerIdx] = passenger;
  }

  removeEmptyPassenger() {
    this.addedPassengers.pop();
  }

  setPassengersCategories(categories) {
    this.selectedCategories = { ...this.selectedCategories, ...categories };
  }

  selectPassenger(passenger, passengerIdx) {
    this.passengers[passengerIdx] = passenger;
  }

  addCardToPassenger(card, passengerIdx) {
    this.passengers[passengerIdx] = {
      ...this.passengers[passengerIdx],
      loyaltyCard: { ...card },
    };
  }

  syncPassengersWithSearch() {
    this.setPassengersCategories({
      adult: searchStore.passengersCount.adults,
      child: searchStore.passengersCount.children,
      infant: searchStore.passengersCount.infants,
    });
    // массив passengers из passNum пустых ячеек,
    // чтобы можно было заполнять в любом порядке
    const passNum =
      searchStore.passengersCount.adults +
      searchStore.passengersCount.children +
      searchStore.passengersCount.infants;
    this.passengers = new Array(passNum);
  }
}

decorate(PassengersStore, {
  countries: observable,
  passengers: observable.deep,
  addedPassengers: observable,
  crmPassengers: observable.deep,
  arePassengersLoading: observable,
  shouldPassengersLoad: observable,
  setAddedPassenger: action,
  setCrmPassenger: action,
  setCustomerDocs: action,
  addEmptyPassenger: action,
  removeEmptyPassenger: action,
  loadPassengersFromCRM: action,
  setShouldPassengersLoad: action,
  selectPassenger: action,
  setPassenger: action,
  addCardToPassenger: action,
});

export default new PassengersStore();
export { EMPTY_PASSENGER_DOC_TEMPLATE, RUSSIA_TEMPLATE };
