import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  I18N_DEFAULT_LNG,
  I18N_DEBUG,
  I18N_QUERY_PARAM_NAME,
} from './constants';
import commonStore from './stores/commonStore';
import translationRu from './translations/ru.json';
import translationEn from './translations/en.json';

i18n
  .use(initReactI18next)
  .use({
    type: 'languageDetector',
    async: false,
    init() {},
    detect() {
      const { initialQueryVars } = commonStore;
      if (initialQueryVars[I18N_QUERY_PARAM_NAME]) {
        return initialQueryVars[I18N_QUERY_PARAM_NAME];
      }
      return I18N_DEFAULT_LNG;
    },
    cacheUserLanguage(lng) {
      window.localStorage.setItem('i18nLng', lng);
    },
  })
  .init({
    resources: {
      ru: translationRu,
      en: translationEn,
    },
    debug: I18N_DEBUG,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
