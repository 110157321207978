/* global __VERSION__ */
import React from 'react';
import ReactDOM from 'react-dom';
import { setMomentLocale } from 'react-scroll-calendar';
import moment from 'moment';

import 'moment/locale/ru';
import 'normalize.css';
import i18n from './i18n';
import './assets/fonts/fonts.css';
import userStore from './stores/userStore';
import App from './App';

function setup() {
  moment.locale(i18n.language);
  setMomentLocale(i18n.language);
  userStore.checkAuth();
}

function run() {
  const container = document.getElementById('root');
  ReactDOM.render(<App />, container);
  // eslint-disable-next-line
  console.info(`Running v${__VERSION__}`);
}

function main() {
  setup();
  run();
}

main();
