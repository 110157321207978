import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from '../../../assets/images/icons/warning.svg';

const Container = styled.span`
  svg {
    #warning-icon {
      fill: ${(props) => props.theme.colors.iconsPrimaryColor};
    }
  }
`;

const WarningIcon = () => (
  <Container>
    <Icon />
  </Container>
);

export default WarningIcon;
