import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';

const SearchBarContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px 10px 10px 34px;
  border: none;
  outline: none;
  margin: 0;
  font-size: 16px;
  line-height: 36px;
  background-color: #f4f2ef;
`;

const SearchBar = (props) => {
  const {
    inputId,
    autoComplete,
    readOnly,
    placeholder,
    value,
    onChange,
  } = props;
  return (
    <SearchBarContainer>
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <SearchInput
        id={inputId}
        autoComplete={autoComplete === false ? 'off' : 'on'}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
