import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Icon } from '../../../assets/images/icons/tickets.svg';

const Container = styled.span`
  svg {
    #tickets-ticket-1 {
      fill: ${(props) => props.theme.colors.iconsPrimaryColor};
    }
    #tickets-ticket-2 {
      fill: ${(props) => props.theme.colors.iconsSecondaryColor};
    }
  }
`;

const TicketsIcon = () => (
  <Container>
    <Icon />
  </Container>
);

export default TicketsIcon;
