import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from '../../../assets/images/icons/passengers.svg';
import { FLIGHT_CLASSES } from '../../../constants';

const Container = styled.div`
  position: relative;
  cursor: pointer;
  padding: 15px 15px 15px 41px;
  outline: ${(props) => (props.error ? '1px solid red' : 'none')};
`;
const IconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto;
  width: 16px;
  height: 16px;
`;
const PassengerText = styled.div`
  display: inline-block;
  color: ${(props) =>
    props.error
      ? props.theme.colors.inputErrorColor
      : props.theme.colors.textColor};
  font-size: 16px;
`;
const FLIGHT_CLASS_NAMES = {
  [FLIGHT_CLASSES.ECONOMY]: 'economy',
  [FLIGHT_CLASSES.BUSINESS]: 'business',
  [FLIGHT_CLASSES.FIRST]: 'first',
};

const PassengerSelector = (props) => {
  const { t } = useTranslation(['search', 'common']);
  const theme = useTheme();
  const { flightClass, passengersCount, error } = props;
  const totalPassengersCount = Object.entries(passengersCount).reduce(
    (total, [, count]) => total + count,
    0,
  );
  const flightClassName = t(FLIGHT_CLASS_NAMES[flightClass]);

  const text = `${totalPassengersCount} ${t('common:passengers', {
    count: totalPassengersCount,
  })}, ${flightClassName}`;

  return (
    <Container>
      <IconContainer>
        <Icon fill={theme.colors.textColorLight} />
      </IconContainer>
      <PassengerText error={error}>{text}</PassengerText>
    </Container>
  );
};

export default PassengerSelector;
