import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReverseIcon } from '../../../assets/images/icons/reverse.svg';
import LocationInput from './LocationInput';
import { useStores } from '../../../hooks/useStores';

const Container = styled.div`
  padding-top: 1px;
`;
const Separator = styled.div`
  position: relative;
  z-index: 999;
`;
const SeparatorLine = styled.div`
  margin-left: 40px;
  width: 253px;
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.borderColor};
`;
const ReverseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: -7px;
  right: 25px;
  width: 16px;
  height: 16px;
`;

const LocationsSelectorFullWidth = observer((props) => {
  const { t } = useTranslation(['search']);
  const { route, errors } = props;
  const { searchStore } = useStores();
  const theme = useTheme();
  return (
    <Container>
      <Link
        to="/search/location"
        onClick={() => searchStore.editLocationOnRoute('from', route)}
      >
        <LocationInput
          error={errors.from}
          placeholder={t('from')}
          value={route.from}
        />
      </Link>
      <Separator>
        <SeparatorLine />
        <ReverseButton
          onClick={() => searchStore.reverseLocationsOnRoute(route)}
        >
          <ReverseIcon fill={theme.colors.textColorLight} />
        </ReverseButton>
      </Separator>
      <Link
        to="/search/location"
        onClick={() => searchStore.editLocationOnRoute('to', route)}
      >
        <LocationInput
          error={errors.to}
          placeholder={t('to')}
          value={route.to}
        />
      </Link>
    </Container>
  );
});

export default LocationsSelectorFullWidth;
