import React from 'react';
import { Global, css, useTheme } from '@emotion/react';

const DatesStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        .avia-calendar {
          width: 308px !important;
          padding-bottom: 30px;
          margin: 0 auto;
        }

        .avia-calendar .month > div {
          font-size: 16px !important;
          color: #000 !important;
          margin-bottom: 10px;
        }

        .avia-calendar ul.days li {
          font-weight: 500 !important;
          text-transform: lowercase !important;
          font-size: 14px !important;
          color: #a7abac !important;
          width: 44px !important;
          height: 44px !important;
          line-height: 44px !important;
          box-sizing: border-box;
        }

        .avia-calendar .day-container,
        .avia-calendar .month ul li {
          width: 44px !important;
        }

        .avia-calendar .day {
          text-align: center;
          line-height: 44px !important;
          display: inline-block;
          color: #000;
          font-size: 16px !important;
          width: 44px !important;
          height: 44px !important;
          box-sizing: border-box;
          margin-bottom: 0 !important;
          position: relative;
        }

        .avia-calendar ul.date span {
          position: relative;
          width: auto !important;
          height: auto !important;
          line-height: inherit !important;
        }

        .avia-calendar .day.active {
          font-weight: 400 !important;
          color: #fff;
        }

        .avia-calendar .day.active span {
          background-color: inherit !important;
          font-weight: 400 !important;
        }

        .avia-calendar .active-overlay {
          position: absolute;
          width: 40px;
          height: 40px;
          background-color: ${theme.colors.primaryColor};
          border-radius: 25px;
          left: 2px;
          top: 2px;
        }

        .avia-calendar .active-period-overlay {
          position: absolute;
          width: 44px;
          height: 40px;
          background-color: ${theme.colors.primaryColor};
          opacity: 0.1;
          left: 5px;
          top: 2px;
        }

        .avia-calendar .active .active-period-overlay {
          width: 24px;
          left: 25px;
        }

        .avia-calendar .active .active-period-overlay.end {
          left: 5px;
          width: 23px;
        }

        .avia-calendar .month ul.date li span:hover {
          font-weight: inherit !important;
        }

        .avia-calendar .day-container {
          margin-bottom: 0 !important;
          height: 44px;
        }
      `}
    />
  );
};

export default DatesStyles;
