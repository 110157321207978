import React from 'react';
import 'react-tippy/dist/tippy.css';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@emotion/react';

import { DefaultTheme } from './themes';
import commonStore from './stores/commonStore';
import userStore from './stores/userStore';
import searchStore from './stores/searchStore';
import locationSearchStore from './stores/locationSearchStore';
import flightSearchStore from './stores/flightSearchStore';
import remarksStore from './stores/remarksStore';
import passengersStore from './stores/passengersStore';
import passengerEditorStore from './stores/passengerEditorStore';
import reservationStore from './stores/reservationStore';
import bookingStore from './stores/bookingStore';
import filterStore from './stores/filterStore';
import popupsStore from './stores/popupsStore';
import GlobalStyles from './globalStyles';
import PopupsHost from './components/common/PopupsHost';
import Router from './Router';

const stores = {
  userStore,
  commonStore,
  searchStore,
  locationSearchStore,
  flightSearchStore,
  remarksStore,
  passengersStore,
  passengerEditorStore,
  reservationStore,
  bookingStore,
  filterStore,
  popupsStore,
};

function App() {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Provider {...stores}>
      <ThemeProvider theme={DefaultTheme}>
        <div className="App">
          <PopupsHost />
          <GlobalStyles />
          <Router />
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
